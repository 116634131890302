import React, { useState, useEffect } from "react";
import { getIndependentParts } from "../../../../../../../apiServices/generalService";
import { Autocomplete } from "../../../../../../../components/Autocomplete";
import { useSnackbar } from "notistack";
import { UAVTErrorText } from "./../../../../../constants";

const IndependentPartSelect = ({ building, textValue, isUavt = true, error, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { value, onChange } = rest;
	const [uavt,setUavt] = useState(isUavt)

  useEffect(() => {
    async function fillOptions() {
      try {
        setLoading(true);
        const response = (await getIndependentParts(building)).data;
        /**
         * İlk elemanın Text'i undefined gelebiliyor. User-friendly görünüm için " - " set ediyoruz
         */
        if (response.length > 0 && response.find(r=> r.Text === "") !== undefined) {
          response.find(r=> r.Text === "").Text = " - ";
        }
        setOptions(response);

        //current value yeni options içinde yok ise value'u sıfırla
        if (value) {
          const matchingOption = response.find((x) => x.Value === value.Value);
          if (matchingOption) {
            onChange(matchingOption.Value, matchingOption);
          } else {
            onChange && onChange(undefined, undefined);
          }
        }
      } catch (error) {
        enqueueSnackbar(UAVTErrorText, { variant: "error" });
      } finally {
        setLoading(false);
      }
    }

    if (building && uavt) {
      fillOptions();
    } else {
      if (options.length !== 0) onChange && onChange(undefined, undefined)      
      setOptions([]);
    }
		setUavt(true);

    /**
     * Options değişirse seçili değer temizlenmeli
     */
    // if (prevBuilding) {
    //   value && onChange && onChange(undefined, undefined);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  return <Autocomplete options={options} loading={loading} error={error} {...rest} />;
};

export default IndependentPartSelect;
