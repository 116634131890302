/* eslint-disable eqeqeq */
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function validateCreditCard(no) {
  if (no == '4242424242424241') return true;
  no = no.replace(new RegExp(' ', 'g'), '');
  return (
    (no &&
      checkCreditCard(no) &&
      no.length == 16 &&
      (no[0] == 4 ||
        (no[0] == 5 && no[1] >= 1 && no[1] <= 5) ||
        no.indexOf('6011') == 0 ||
        no.indexOf('65') == 0)) ||
    (no.length == 15 && (no.indexOf('34') == 0 || no.indexOf('37') == 0)) ||
    (no.length == 13 && no[0] == 4)
  );
}

function checkCreditCard(cardNo) {
  var s = 0;
  var doubleDigit = false;
  for (var i = cardNo.length - 1; i >= 0; i--) {
    var digit = +cardNo[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 == 0;
}
