import React, { useContext } from 'react';
import { Divider, Grid, TextField } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { Controller } from 'react-hook-form';
import PaymentContext from '../context/PaymentContext';
import { Skeleton } from '@material-ui/lab';

const FlatAdditionalInfoForm = ({ form }) => {
  const { control, formState } = form;
  const { errors } = formState;
  const { isInit } = useContext(PaymentContext);

  return (
    <>
      <h1
        style={{
          fontSize: 22,
          fontWeight: 'bold',
          color: '#0055a5',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <BusinessIcon /> &nbsp; Dairenizin Ek Bilgileri
      </h1>
      <Divider />
      <br />
      {isInit ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="address.block"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.cardOwnerName}
                  label="Bina Ada No"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="address.plot"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.cardOwnerName}
                  label="Bina Pafta No"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="address.lot"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.cardOwnerName}
                  label="Bina Parsel No"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="address.page"
              control={control}
              rules={{
                maxLength: 10
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.cardOwnerName}
                  label="Bina Sayfa No"
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        Array(10)
          .fill(null)
          .map((x, i) => <Skeleton key={i} />)
      )}
    </>
  );
};

export default FlatAdditionalInfoForm;
