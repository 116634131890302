export const BDNRJTMCK = {
	header: {
		left: {
			authorizedAgencyLogo: false,
		},
		right: {
			phoneNumber: '',
			partnerLogo: true,
		},
	},
};
