export const PolicyType = {
  NEW: 1,
  RENEWAL: 2
};

export const AddressType = {
  NEW: 1,
  EXIST: 2
};

export const DaskHomeSectionKey = {
  Personal: 1,
  Address: 2,
  Flat: 3,
  MultipleQuotations: 4,
  QuoteSummary: 5,
  DetailedAddress: 6
};

export const EntryType = {
  uavt: 'uavt',
  new: 'newAddress'
}

export const errorTextForCheckQuote =
  "Sistemdeki mevcut bilgilerinizle teklif alınamıyor. Lütfen bilgilerinizi girerek, Koalay.com'un size özel yeni tekliflerini görün.";

export const UnhandledErrorText = 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.';
export const UAVTErrorText =
  'Belirtilen adresle eşleşen herhangi bir bilgiye ulaşılamamaktadır. Lütfen adresin nüfus müdürlüğünde kayıtlı olduğundan emin olunuz.';
export const MeskenUsageTypeId = '5';
