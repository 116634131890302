import React, { useContext, useState } from 'react';
import { Box, Button, Typography, Grid } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { AppContext } from '../../providers/AppProvider';

const CookieModal = (name, value, days) => {
  /**
   * showCookie state'ini initiliaze etmek için kullanılır
   * cookie izni yoksa modalı göster
   * @returns true | false
   */
  const initiliazeShowCookie = () => {
    return (
      !readCookie('kCookiePermission') ||
      readCookie('kCookiePermission') === '0'
    );
  };

  const [showCookie, setShowCookie] = useState(() => initiliazeShowCookie());
  const { isMobile } = useContext(AppContext);

  function createCookie(name, value, days) {
    let expires;

    if (days !== 0) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    } else {
      localStorage.setItem('showCookie', true);
      expires = ';';
    }

    document.cookie = name + '=' + value + expires + '; path=/';
  }

  function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    if (localStorage.getItem('showCookie')) {
      localStorage.removeItem('showCookie');
      createCookie('kCookiePermission', 0, 365);
    }
    return null;
  }

  function accept() {
    createCookie('kCookiePermission', 1, 365);
    setShowCookie(false);
  }

  function hide() {
    createCookie('kCookiePermission', 2, 0);
    setShowCookie(false);
  }

  function MobileCookie() {
    return (
      <Grid container>
        <Grid item xs={11} style={{ textAlign: 'left' }}>
          {' '}
          <Typography variant="p">
            Deneyiminizi daha iyi hale getirmek için çerezler kullanıyoruz.
            Devam ederek çerez kullanımımızı kabul etmiş oluyorsunuz.{' '}
            <a
              style={{ color: '#42a5f5' }}
              href="https://www.koalay.com/yardim/cerez-politikasi"
            >
              {' '}
              Detaylı Bilgi.
            </a>{' '}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          {' '}
          <IconButton onClick={hide} style={{ padding: 0 }}>
            <ClearIcon style={{ color: '#fff' }} />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={accept}
          >
            Kabul Et
          </Button>
        </Grid>
      </Grid>
    );
  }

  function DesktopCookie() {
    return (
      <>
        {' '}
        <Typography variant="p">
          Deneyiminizi daha iyi hale getirmek için çerezler kullanıyoruz. Devam
          ederek çerez kullanımımızı kabul etmiş oluyorsunuz.{' '}
          <a
            style={{ color: '#42a5f5' }}
            href="https://www.koalay.com/yardim/cerez-politikasi"
          >
            {' '}
            Detaylı Bilgi.
          </a>{' '}
        </Typography>
        <Button
          style={{ margin: '0 5px 0 35px' }}
          size="small"
          color="primary"
          variant="contained"
          onClick={accept}
        >
          Kabul Et
        </Button>{' '}
        <IconButton onClick={hide}>
          <ClearIcon style={{ color: '#fff' }} />
        </IconButton>
      </>
    );
  }
  return showCookie ? (
    <Box
      style={{
        position: 'fixed',
        textAlign: 'center',
        backgroundColor: '#333',
        color: '#fff',
        padding: isMobile ? '15px' : '5px 25px 5px 15px',
        width: '100%',
        bottom: 0,
        fontSize: 12
      }}
    >
      {isMobile ? <MobileCookie /> : <DesktopCookie />}
    </Box>
  ) : (
    <React.Fragment />
  );
};

export default CookieModal;
