import axios from 'axios';
import queryString from 'query-string';

const API = process.env.NODE_ENV;
const consumer = queryString
  .stringify(queryString.parse(window.location.search))
  .replaceAll('&', ';')
  .replaceAll('=', ':');

const axiosInstance = axios.create({
  baseURL: API === "development" ? 'http://testself.koalay.corp.telesure.com.tr/Api/' : document.location.origin + '/Api/',
  headers: {
    'Content-Type': 'application/json',
    RequestVerificationToken: localStorage.getItem('aft'),
    'Access-Control-Allow-Origin': '*',
    'X-Koalay-Consumer': consumer
  }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        RequestVerificationToken: localStorage.getItem('aft')
      }
    };
  },
  function (error) {}
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data.code && response.data.code !== '0') {
      return Promise.reject({
        message: response.data.message,
        code: response.data.code
      });
    }
    return response;
  },
  function (error) {
    if(error.response.status==302){
        document.location.href = error.response.data.redirectUrl;
    }else if(error.response.status==403){
      document.location.pathname = "/Error/Unauthorized";
    }
    //  setLoading(false);
    return Promise.reject(error);
  }
);

export default axiosInstance;
