import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../providers/AppProvider';
import { blue } from '@material-ui/core/colors';

/**
 *! Dialog open with fullscreen when is mobile
 *! consoleda “findDOMNode is deprecated in StrictMode” hatası fırlatıyor.
 *! https://stackoverflow.com/questions/63568669/dialog-with-transition-throwing-js-warning-finddomnode-is-deprecated-in-strictm
 */
export function KoalayDialog({ title, content, children, closeButtonTitle }) {
  const [visible, setVisible] = React.useState(false);
  const { isMobile } = useContext(AppContext);
  return (
    <>
      <span style={{ color: blue[700], cursor: 'pointer' }} onClick={() => setVisible(true)}>
        {children}
      </span>
      <MuiDialog
        fullScreen={isMobile}
        maxWidth={'md'}
        onClose={() => setVisible(false)}
        aria-labelledby="dialog-title"
        open={visible}
      >
        <DialogTitle id="dialog-title" onClose={() => setVisible(false)}>
          {title}
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        {closeButtonTitle && (
          <DialogActions>
            <Button onClick={() => setVisible(false)} color="primary">
              {closeButtonTitle}
            </Button>
          </DialogActions>
        )}
      </MuiDialog>
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                    TİTLE                                   */
/* -------------------------------------------------------------------------- */

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      color: '#fff'
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      color: '#fff'
    }
  }
}))(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 400
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
