export const gaEvents = {
	PolicyAddressSeen: { category: 'yeni police', action: 'poliçe yapılacak adres stepini gören',}, // adres stepi açıldığında setlendi
	PolicyAddressContinue: { category: 'yeni police', action: 'poliçe yapılacak adres stepinde görünen adresle devam edenler',}, // bu adresle devam et tıklandığında setlendi
	PolicyAddressNew: { category: 'yeni police', action: 'police yapılacak adres stepinde başka adres seçmek istiyorum diyen',}, // başka adres seçmek istiyorum tıklandığında setlendi
	PolicyAddressNewUavt: { category: 'yeni police', action: 'başka adres seçmek istiyorum diyenden uavt kodu ile arama yapanlar',}, // Ara butonu tıklandığında
	PolicyAddressNewAddress: { category: 'yeni police', action: 'başka adres seçmek istiyorum diyenden adresini girerek devam et',}, // bu adresle devam et tıklandığında
	PolicyAddressNewCancel: { category: 'yeni police', action: 'başka adres seçmek istiyorum diyenden görünen adrese dönüp bu adresle devam et diyen',}, // görünen adrese dönmek istiyorum tıklandığında
	PolicyApartmentInfoSeen: { category: 'yeni police', action: 'daire bilgileri stepini gören',}, // daire bilgileri açıldığında setlendi
	PolicyOfferInfoSeen: { category: 'yeni police', action: 'teklif bilgileri stepini gören',},
	PolicyDmYes: { category: 'yeni police', action: 'rehinli alacaklı evet diyen',}, // police satın al tıklandığında
	PolicyDmNo: { category: 'yeni police', action: 'rehinli alacaklı hayır diyen',}, // police satın al tıklandığında
	PolicyCheckoutSeen: { category: 'yeni police', action: 'ödeme sayfasını gören',}, // checkout sayfası açıldığında setlendi
	PolicyThankyouSeen: { category: 'yeni police', action: 'thankyou page gören',}, // thankyou sayfası açıldığında setlendi
	RenewalAddressSeen: { category: 'yenileme poliçe', action: 'police yapılacak adres stepini gören',},
	RenewalApartmentInfoSeen: { category: 'yenileme poliçe', action: 'daire bilgileri stepini gören',},
	RenewalOfferInfoSeen: { category: 'yenileme poliçe', action: 'teklif bilgileri stepini gören',},
	RenewalDmYes: { category: 'yenileme poliçe', action: 'rehinli alacaklı evet diyen',},
	RenewalDmNo: { category: 'yenileme police', action: 'rehinli alacaklı hayır diyen',},
	RenewalCheckoutSeen: { category: 'yenileme police', action: 'checkout page gören',}, // checkout sayfası açıldığında setlendi
	RenewalThankyouSeen: { category: 'yenileme police', action: 'thankyou page gören',}, // thankyou sayfası açıldığında setlendi
}
