/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import { getQuotes, saveLossPayee, updateUserAddress } from "../../../../apiServices/sideProductService";
import { getDaskPolicyDetail, getProductParameters } from "../../../../apiServices/generalService";

import { useSnackbar } from "notistack";
import { AddressEntrySection, PersonelInfoEntrySection, FlatInfoEntrySection, QuoteSummarySection } from "./sections";
import { DaskHomeContext } from "./context/DaskHomeContext";
import { useHistory } from "react-router-dom";

import { AppContext } from "../../../../providers/AppProvider";
import { AddressType, DaskHomeSectionKey, UnhandledErrorText } from "../../constants";
import { PolicyType } from "../../constants";
import { useForm, FormProvider } from "react-hook-form";
import RedirectScreen from "../RedirectScreen";
import MultipleQuotations from "./sections/MultipleQuotationsSection";

const DaskFunnel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { setFunnelType, isMobile, funnelData, setMainLogoHref } = useContext(AppContext);
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const extRefHash = URL_PARAMS.get('extRefHash');
  const [redirect, setRedirect] = useState(false);
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      policyType: isMobile ? undefined : PolicyType.NEW,
      customer: {
        identityNo: funnelData?.referenceParams?.identityNo,
        phoneNumber: funnelData?.referenceParams?.phone,
        birthDate: null,
      },
    },
  });

  //TODO to be implemented
  const isForcePrepare = false;

  /**
   * ReferenceParams ile initiliaze etmek için kullanılır
   */
  useEffect(() => {
    if (funnelData?.consumer?.referenceParams) {
      methods.setValue("customer.identityNo", funnelData?.consumer?.referenceParams?.identityNo);
      methods.setValue("customer.phoneNumber", funnelData?.consumer?.referenceParams?.phone);
    }
  }, [funnelData]);

  useEffect(() => {
    localStorage.setItem("uavtCode", "undefined");
    setRedirect(JSON.parse(localStorage.getItem("consumer")).type === "SelfServiceWL");
  }, []);

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        setRedirect(false);
      }, 5000);
    }
  }, [redirect]);
  /* -------------------------------------------------------------------------- */
  /*                                    REFs                                    */
  /* -------------------------------------------------------------------------- */
  const addressStepRef = useRef();
  const flatStepRef = useRef();
  const quoteStepRef = useRef();
  const multipleQuotesStepRef = useRef();

  /* -------------------------------------------------------------------------- */
  /*                                   STATES                                   */
  /* -------------------------------------------------------------------------- */

  const [activeSectionKey, setActiveSectionKey] = useState(DaskHomeSectionKey.Personal);

  const [productParameters, setProductParameters] = useState(undefined);
  const [, setDaskQuote] = useState(undefined);
  const [daskResultQuote, setDaskResultQuote] = useState(undefined);
  const [multipleDaskQuotes, setMultipleDaskQuotes] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [addressOptions, setAddressOptions] = useState({
    cities: [],
    towns: [],
    villages: [],
    districts: [],
  });

  const contextValue = {
    addressOptions,
    setAddressOptions,
    productParameters,
    daskResultQuote,
    multipleDaskQuotes,
    loading,
    setLoading,
    goPreviousSection,
    isForcePrepare,
  };

  /* -------------------------------------------------------------------------- */
  /*                                 USE EFFECTS                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    setFunnelType(2);
  }, []);

  useEffect(() => {
    if (activeSectionKey === 5) {
      quoteStepRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [activeSectionKey]);

  /* -------------------------------------------------------------------------- */
  /*                              METHODS                                       */
  /* -------------------------------------------------------------------------- */

  function goPreviousSection() {
    activeSectionKey !== DaskHomeSectionKey.PersonelInfo && setActiveSectionKey(activeSectionKey - 1);
  }

  function goPayment() {
    const { quotationUID, teklifTId } = daskResultQuote;
    if (document.location.href.indexOf("Token") >= 0 || document.location.href.indexOf("ReferenceId") >= 0) {
      push(`/dask/payment${document.location.search}&qGuid=${quotationUID}&teklifTId=${teklifTId}`);
    } else if (document.location.href.indexOf("extRefHash") >= 0) {
      push(`/dask/payment?qGuid=${quotationUID}&teklifTId=${teklifTId}&extRefHash=${extRefHash}`);
    } else {
      push(`/dask/payment?qGuid=${quotationUID}&teklifTId=${teklifTId}`);
    }
  }

  async function getOfferAndGoSummarySection(address, building, customer) {
    try {
      setLoading(true);
      //* Below is needed to show skeleton */
      if (localStorage.getItem('daskmultiplequotationenabled') === 'true') {
        setTimeout(() => {
          setLoading(false);
          setMultipleDaskQuotes([]);
          //go multipleQuotes step
          setActiveSectionKey(DaskHomeSectionKey.MultipleQuotations);
          multipleQuotesStepRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }, 3000);
      }
      const addressType = methods.getValues("addressType");
      let quoteModel = {
        address,
        addressBreakout: addressType === AddressType.EXIST ? false : true,
        building,
        customer,
        productGroupId: 3,
        functionalBranchId: 2,
      };
      quoteModel.address.aPSAdress = addressType === AddressType.EXIST ? true : false;

      const oldPolicyNo = methods.getValues("oldPolicyNo");

      if (oldPolicyNo) {
        quoteModel.OldPolicyNo = oldPolicyNo;
      }
      const quoteResponseModel = (await getQuotes(quoteModel)).data;

      setDaskQuote(quoteResponseModel);
      if (localStorage.getItem('daskmultiplequotationenabled') === 'true') {
				setMultipleDaskQuotes(quoteResponseModel.Result.quotations);
        //go multipleQuotes step
        setActiveSectionKey(DaskHomeSectionKey.MultipleQuotations);
        multipleQuotesStepRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else {
				setDaskResultQuote(quoteResponseModel.Result.quotations[0]);
        //go quoteSummary step
        setActiveSectionKey(DaskHomeSectionKey.QuoteSummary);
        quoteStepRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }

      return Promise.resolve(true);
    } catch (error) {
      enqueueSnackbar(error.message || UnhandledErrorText, {
        variant: "error",
      });
      return Promise.reject(false);
    } finally {
      setLoading(false);
    }
  }

  async function selectOfferAndGoSummarySection(quote) {
    try {
      setLoading(true);
        setDaskResultQuote(quote);
        //go quoteSummary step
        setActiveSectionKey(DaskHomeSectionKey.QuoteSummary);
      return Promise.resolve(true);
    } catch (error) {
      enqueueSnackbar(error.message || UnhandledErrorText, {
        variant: "error",
      });
      return Promise.reject(false);
    } finally {
      setLoading(false);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                              STEP ON COMPLETE                              */
  /* -------------------------------------------------------------------------- */

  /**
   * PersonelInfo(Step 1) on complete
   */
  const completePersonelInfoEntrySection = () => {
    setActiveSectionKey(DaskHomeSectionKey.Address);

    addressStepRef.current.scrollIntoView({
      behavior: "smooth",
    });
    setMainLogoHref("/#");
  };

  /**
   * AddressInfo(Step 2) on complete
   */
  const completeAdressEntrySection = async () => {
    try {
      setLoading(true);
      const productParameters = (await getProductParameters(3)).data;
      const {
        BuildingStories: buildingStories,
        ConstructionTypes: constructionTypes,
        ConstructionYears: constructionYears,
        UsageTypes: usageTypes,
      } = productParameters;

      setProductParameters({
        buildingStories,
        constructionTypes,
        constructionYears,
        usageTypes,
      });
      const addressType = methods.getValues("addressType");
      const adrs = addressType === AddressType.NEW ? "newAddress" : "existAddress";

      if (adrs === "newAddress") {
        getDaskPolicyDetail(
          methods.getValues("customer").identityNo,
          methods.getValues("newAddress").independentPart.Value,
          methods.getValues("customer").userGuid,
          (building) => {
            if (building.buildingInfo !== null && building.daskPolicyNo !== null) {
              methods.setValue("oldPolicyNo", building.daskPolicyNo);
              methods.setValue("defaultBuilding", {
                ...building.buildingInfo,
                constructionType: building.buildingInfo.constructionType + "",
                usageType: building.buildingInfo.usageType + "",
                constructionYear: building.buildingInfo.constructionYear + "",
                floorArea: building.buildingInfo.floorArea + "",
                buildingStory: building.buildingInfo.buildingStory + "",
              });
            }
            setActiveSectionKey(DaskHomeSectionKey.Flat);
            flatStepRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }
        );
      } else {
        setActiveSectionKey(DaskHomeSectionKey.Flat);
        flatStepRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      // Go FlatInfo step
    } catch (error) {
      enqueueSnackbar(error.message || UnhandledErrorText, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * FlatInfo (step 3) on complete
   */
  const completeFlatInfoEntrySection = async () => {
    try {
      setLoading(true);
      const newAddress = methods.getValues("newAddress");
      const existAddress = methods.getValues("existAddress");
      const addressType = methods.getValues("addressType");
      const building = methods.getValues("building");
			const buildingsWithIntValues = Object.entries(building).reduce((acc, [key, value]) => {
				if(key !== "propertyUID"){
					acc[key] = parseInt(value, 10);
				}else{
					acc[key] = value
				}
				return acc;
			}, {});
      const customer = methods.getValues("customer");
      if (newAddress !== undefined) {
        newAddress.AddressNum = newAddress.independentPart.Value;
        newAddress.innerDoorNum = newAddress.independentPart.Text;
        newAddress.outerDoorNum = newAddress.building.Text;
      }

      const payloadAddress = addressType === AddressType.NEW ? newAddress : existAddress;

      await getOfferAndGoSummarySection(payloadAddress, buildingsWithIntValues, customer);
    } finally {
      setLoading(false);
    }
  };

  /**
   * QuoteSummary (step 4) on complete
   * * Change main logo src
   * * SaveLossPayee if has daini murtehin
   * * If it is new address go to detailed Adress(UAVT) section else if go payment
   */
  const completeQuoteSummarySection = async (saveLossPayeeModel) => {
    if (saveLossPayeeModel) {
      try {
        await saveLossPayee(saveLossPayeeModel);
      } catch (error) {
        enqueueSnackbar(UnhandledErrorText, { variant: "error" });
      }
    }
    goPayment();
  };

  /**
   * Detailed Address-UAVT on complete
   */
  const completeDetailedAddressEntrySection = async () => {
    const newAddress = methods.getValues("newAddress");
    const updatedAddress = {
      ...newAddress,
      AddressNum: newAddress.independentPart.Value,
      InnerDoorNum: newAddress.independentPart.Text,
      OuterDoorNum: newAddress.building.Text,
      BuildingCode: newAddress.building.Value,
    };

    const payloadModel = {
      teklifUId: daskResultQuote?.quotationUID,
      address: updatedAddress,
    };

    try {
      await updateUserAddress(payloadModel);
      goPayment();
    } catch (error) {
      enqueueSnackbar(error.message || UnhandledErrorText, {
        variant: "error",
      });
    }
  };

  function showSection(sectionKey) {
    if (activeSectionKey === DaskHomeSectionKey.DetailedAddress) {
      return sectionKey === DaskHomeSectionKey.DetailedAddress;
    }

    if (isMobile) {
      return sectionKey === activeSectionKey;
    } else {
      if (localStorage.getItem('daskmultiplequotationenabled') !== 'true' && sectionKey === DaskHomeSectionKey.MultipleQuotations) {
        return false;
      }
      return sectionKey <= activeSectionKey;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  if (!funnelData) {
    return <> </>;
  }
  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <DaskHomeContext.Provider value={contextValue}>
          <>
            {redirect && <RedirectScreen></RedirectScreen>}
            {showSection(DaskHomeSectionKey.Personal) && (
              <PersonelInfoEntrySection
                isActive={activeSectionKey === DaskHomeSectionKey.Personal}
                completeSection={completePersonelInfoEntrySection}
              />
            )}
            {showSection(DaskHomeSectionKey.Address) && (
              <AddressEntrySection
                isActive={activeSectionKey === DaskHomeSectionKey.Address}
                ref={addressStepRef}
                completeSection={completeAdressEntrySection}
              />
            )}
            {showSection(DaskHomeSectionKey.Flat) && (
              <FlatInfoEntrySection
                isActive={activeSectionKey === DaskHomeSectionKey.Flat}
                ref={flatStepRef}
                completeSection={completeFlatInfoEntrySection}
              />
            )}
            {showSection(DaskHomeSectionKey.MultipleQuotations) && (
              <MultipleQuotations
                isActive={activeSectionKey === DaskHomeSectionKey.MultipleQuotations}
                ref={multipleQuotesStepRef}
                completeSection={selectOfferAndGoSummarySection}
              />
            )}
            {showSection(DaskHomeSectionKey.QuoteSummary) && (
              <QuoteSummarySection
                isActive={activeSectionKey === DaskHomeSectionKey.QuoteSummary}
                ref={quoteStepRef}
                completeSection={completeQuoteSummarySection}
              />
            )}
          </>
        </DaskHomeContext.Provider>
      </form>
    </FormProvider>
  );
};

export default DaskFunnel;
