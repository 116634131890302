import { Grid } from '@material-ui/core';
import React from 'react';
import { KoalayDialog } from '../KoalayDialog';

const VeriSignInfo = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <img
          id="img-verisign"
          src="https://static.koalay.com/Images/KoalayApp/Quotation/versign-logo.png"
          alt="verisign"
          style={{
            margin: 'auto 8px 16px 0',
            width: 106
          }}
        />
      </Grid>
      <Grid item xs={7}>
        Koalay kredi kartı bilgilerinizi asla saklamaz ve paylaşmaz.{' '}
        <KoalayDialog
          title="Neden Güvenli ?"
          content="İçiniz rahat olsun! Koalay SSL (Secure Sockets Layer) güvenlik sertifikası sahibi. Bu sertifikaya sahip
                    olmak isteyen diğer tüm firmalar gibi, Koalay da çok sıkı bir güvenlik prosedüründen geçti. Bu nedenle
                    teklif alırken ya da poliçenizi satın alırken gireceğiniz her türlü kişisel bilginiz ve kredi kartı detaylarınız
                    ilgili sunuculara şifrelenerek güvenli bir şekilde iletilir, kredi kartı bilgileriniz asla kayıt altına alınmaz ve saklanmaz."
        >
          Neden güvenli ?
        </KoalayDialog>
      </Grid>
    </Grid>
  );
};

export default VeriSignInfo;
