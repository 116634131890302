import React, { useState, useEffect } from 'react';
import { getStreets } from '../../../../../../../apiServices/generalService';
import { Autocomplete } from '../../../../../../../components/Autocomplete';
import { useSnackbar } from 'notistack';
import { UAVTErrorText } from './../../../../../constants';
import { usePrevious } from './../../../../../../../hooks/usePrevious';

const StreetSelect = ({ district, textValue,isUavt=true, error, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
	const [uavt,setUavt] = useState(isUavt)
  const prevDistrict = usePrevious(district);

  const { value, onChange } = rest;
  useEffect(() => {
    async function fillOptions() {
      try {
        setLoading(true);
        const response = (await getStreets(district)).data;
        setOptions(response);
      } catch (error) {
        enqueueSnackbar(UAVTErrorText, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }

    if (district && uavt) {
      fillOptions();
    } else {
      if (options.length !== 0) onChange && onChange(undefined, undefined)      
      setOptions([]);
    }

    /**
     * Options değişirse seçili değer temizlenmeli
     */
    if (prevDistrict) {
      value && onChange && onChange(undefined, undefined);
    }
		setUavt(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district]);

  return <Autocomplete options={options} loading={loading} error={error} {...rest} />;
};

export default StreetSelect;
