// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { getProductPaymentPlan } from '../../../../../apiServices/paymentPlanService';
import { Alert, Skeleton } from '@material-ui/lab';
import KoalaySelect from '../../../../../components/SelectFormControl/KoalaySelect';
import VeriSignInfo from '../../../../../components/VeriSignInfo';
import PaymentContext from '../context/PaymentContext';
import CheckIcon from '@material-ui/icons/Check';
import YearSelect from '../../../../../components/YearSelect';
import MonthSelect from '../../../../../components/MonthSelect';
import { InputLabel } from '@material-ui/core';
import NumberFormat from './../../../../../components/NumberFormat/NumberFormat';
import { KoalayButton } from '../../../../../components/KoalayButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';


const CreditCardForm = ({ form, creditCardOwnershipVisible, onSubmit }) => {
  const { quote, customer, isInit, paymentPlan, setPaymentPlan } = useContext(
    PaymentContext
  );
  const { control, formState, watch, unregister } = form;
  const { errors, touchedFields } = formState;
  const cardNo = watch('payment.CardNo') || '';
  const [differentCardOwner, setDifferentCardOwner] = useState(true)
  const [differentTCKNValue, setDifferentTCKNValue] = useState(null);

  //* This useEffect is needed just to prevent validating TCKN when user selects 'Evet'
  useEffect(() => {
    if (differentCardOwner) {
      delete errors?.payment?.identityNumber;
      delete touchedFields?.payment?.identityNumber;
      unregister("payment.identityNumber");
      setDifferentTCKNValue(null);
      
    }
  }, [differentCardOwner, errors, touchedFields, unregister]);

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  const fillPaymentPlan = async (bin) => {
    //TODO api sonucunda 3 taksit seçeneği gelmemesine rağmen tesself.koalay'da 3 taksit seçeneği option olarak gösteriliyor.
    const cardPaymentPlanOptions =
      (
        await getProductPaymentPlan({
          Bin: bin,
          IdentityType: customer.identityType,
          ProductId: quote.productId,
          QuoteId: quote.quotationUID,
          TotalAmount: quote.grossPremium
        })
      ).data.paymentPlan || [];

    const _paymentPlan = [...(paymentPlan || [])];

    cardPaymentPlanOptions.forEach((item, index) => {
      _paymentPlan[index].disabled = item.disabled;
    });
    setPaymentPlan(_paymentPlan);
  };

  const cardNoOnChange = (onChange) => async (newCardNo) => {
    if (newCardNo.length >= 6 && cardNo.length < 6) {
      fillPaymentPlan(newCardNo);
    }

    onChange(newCardNo);
  };

  const handleDifferentCardOwnerChange = (event) => {
    const _hasDifferentCardOwner = event.target.value === 'true' ? true : false;
    setDifferentCardOwner(_hasDifferentCardOwner);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <h1
        style={{
          fontSize: 22,
          fontWeight: 'bold',
          color: '#0055a5',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <CreditCardIcon /> &nbsp; Kredi kartı bilgileri
      </h1>

      <Divider />
      <br />
      {isInit ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="payment.CardOwnerName"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.payment?.CardOwnerName ? true : false}
                  label="Kart Sahibinin Adı Soyadı"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="payment.CardNo"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { value, onChange } }) => (
                <NumberFormat
                  value={value}
                  onChange={cardNoOnChange(onChange)}
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.payment?.CardNo ? true : false}
                  label="Kredi kartı numarası"
                  format="#### #### #### ####"
                />
              )}
            />
          </Grid>
          <br />
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="payment.ExpMonth"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.payment?.ExpMonth ? true : false}
                    >
                      <InputLabel>Ay</InputLabel>
                      <MonthSelect
                        label="Ay"
                        value={value}
                        onChange={onChange}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="payment.ExpYear"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.payment?.ExpYear ? true : false}
                    >
                      <InputLabel>Yıl</InputLabel>
                      <YearSelect
                        label="Yıl"
                        value={value}
                        onChange={onChange}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="payment.Cvc2"
              control={control}
              rules={{
                required: true,
                maxLength: 3,
                minLength: 3
              }}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  style={{ width: 100 }}
                  label="CCV"
                  size="small"
                  format="###"
                  variant="outlined"
                  error={errors.payment?.Cvc2 ? true : false}
                />
              )}
            />
          </Grid>
          {creditCardOwnershipVisible && (
            <Grid item xs={12}>
            <FormControl component="div">
              <FormLabel component="legend" style={{width: '330px'}}>
                <Grid container>
                  <Grid item xs={11}>
                   <p style={{fontSize: '14px', width: '292px', lineHeight: '22px', margin: '0', color: '#000000DE'}}>Poliçe sahibi ile kredi kartı sahibi aynı kişi mi?</p>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip enterTouchDelay={0} title="SEDDK kararı gereği, poliçe satın almak için ödeme yapılan kredi kartı sahibinin TCKN/Vergi Kimlik No bilgileri sigorta şirketine iletilmelidir.">
                      <InfoIcon color='primary' style={{width: '22px', height: '22px'}} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={differentCardOwner}
                onChange={handleDifferentCardOwnerChange}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio size="small" color="primary" />
                  }
                  label={
                    <span style={{fontSize: '14px'}} class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Evet</span>
                  }
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio size="small" color="primary" />
                  }
                  label={
                    <span style={{fontSize: '14px'}} class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Hayır</span>
                  }
                />
              </RadioGroup>
            </FormControl>
            {!differentCardOwner && (
              <Controller
                name="payment.identityNumber"
                control={!differentCardOwner && control}
                value={differentTCKNValue}
                rules={!differentCardOwner && {
                  minLength: {
                    value: 10,
                    message: "Lütfen T.C kimlik veya Vergi numarınızı kontrol ediniz.",
                  },
                  validate: {
                    validation: (value) => {
                      if (differentCardOwner) return true;
                      if (!value) {
                        return "Lütfen T.C kimlik veya Vergi numarınızı giriniz.";
                      }
                      const tcknRegex = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
                      const vergiNoRegex = /^[0-9]{10}$/;
                      if (value?.match(tcknRegex) || value?.match(vergiNoRegex)) {
                        return true;
                      }
                      return "Lütfen T.C kimlik veya Vergi numarınızı kontrol ediniz.";
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!differentCardOwner && errors?.payment?.identityNumber ? true : false}
                    label="Kart Sahibi TC Kimlik veya Vergi No"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onInput={(e) => {
                      if (e.target.value) {
                        const val = e.target.value.replace(/\D/g, "");
                        const intVal = parseInt(e.target.value);
                        e.target.value = isNaN(intVal) ? "" : val.slice(0, 11);
                        setDifferentTCKNValue(e.target.value);
                      }
                    }}
                  />
                )}
              />
            )}
            </Grid>
          )}
          <Grid item xs={12}>
            {cardNo.length > 5 ? (
              <Controller
                name="payment.paymentPlanId"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl
                    size="small"
                    fullWidth
                    variant="outlined"
                    error={errors.payment?.paymentPlanId ? true : false}
                  >
                    <InputLabel>Ödeme Planı</InputLabel>
                    <KoalaySelect
                      label="Ödeme Planı"
                      style={{ width: 150 }}
                      data={paymentPlan}
                      value={value}
                      onChange={onChange}
                    />
                  </FormControl>
                )}
              />
            ) : (
              <Alert severity="info" variant="outlined">
                <Typography variant="caption">
                  Ödeme planı seçenekleri kredi kartı bilgilerini girdikten
                  sonra görüntülenecektir.
                </Typography>
              </Alert>
            )}
          </Grid>
          <br />
          <br />
          <Grid item>
            <Alert severity="info" xs={12}>
              <Typography variant="caption">
                Kredi kartı bilgilerinizi girip <b>“ödemeyi onaylayın”</b>{' '}
                butonunu tıkladığınızda poliçeniz oluşacaktır. Bu aşamadan sonra
                poliçe dökümanınızı indirebilir veya e–mail adresinize
                gönderebilirsiniz.
              </Typography>
            </Alert>
          </Grid>
							{quote.needsSecurePayment && (
	<Grid item>
	<Alert severity="info" xs={12}>
		<Typography variant="caption">
			Güvenliğiniz sebebi ile işleminiz 3D Secure ile gerçekleştirilecektir.
			</Typography>
	</Alert>
</Grid>
							)}
          <Grid item xs={12}>
            <KoalayButton
              type="submit"
              endIcon={<CheckIcon />}
              fullWidth={true}
            >
              {quote?.grossPremium} ₺ için ödemeyi tamamlayın
            </KoalayButton>
          </Grid>

          <Grid item>
            <VeriSignInfo />
          </Grid>
        </Grid>
      ) : (
        Array(30)
          .fill(null)
          .map((x, i) => <Skeleton key={i} />)
      )}
    </>
  );
};

export default CreditCardForm;
