import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import logo from './logo.svg';
import './App.css';

import { AppProvider } from './providers/AppProvider';

/* ------------------------------- DASK PAGES ------------------------------- */

import { DaskPayment } from './pages/Dask/Steps/Payment';
import { DaskThankYou } from './pages/Dask/Steps/Thankyou';
import { Unauthorized } from './pages/Dask/Unauthorized';
import { DaskHome } from './pages/Dask/Steps/Home';
import GlobalMessageHandler from './components/GlobalMessageHandler';
import { CssBaseline } from '@material-ui/core';

import TagManager from 'react-gtm-module';
import Paratika from './pages/Dask/Steps/Payment/Paratika';
const {REACT_APP_GA_ID} = process.env;

if(document.location.host.indexOf("hesapkurdu") < 0){
	ReactGA.initialize(REACT_APP_GA_ID);
}

const tagManagerArgs = {
  gtmId: document.location.host.indexOf("hesapkurdu") >= 0 ? "GTM-5B9SDM3":'GTM-N34DLK'
};

/* -------------------------------------------------------------------------- */
/*                                   RENDER                                   */
/* -------------------------------------------------------------------------- */

function App() {
  return (
    <AppProvider>
      <CssBaseline />
      <GlobalMessageHandler>
        <Router>
          <Switch>
            <Route path="/dask/payment">
              <DaskPayment />
            </Route>
            <Route path="/dask/PaymentPopup">
              <Paratika />
            </Route>
            <Route path="/dask/thankyou">
              <DaskThankYou />
            </Route>
            <Route path="/dask/unauthorized">
              <Unauthorized />
            </Route>
            <Route path="/">
              <DaskHome />
            </Route>
          </Switch>
        </Router>
      </GlobalMessageHandler>
    </AppProvider>
  );
}

TagManager.initialize(tagManagerArgs);

export default App;
