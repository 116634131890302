import React, { useState, useEffect } from "react";

export const Paratika = (props) => {
	const [contentRef, setContentRef] = useState(null)
	const [paratikaObject, setParatikaObject] = useState(null)
	const paymentUrl = document.location.href.indexOf("testself") >= 0 || document.location.href.indexOf("localhost") >= 0 ? `https://entegrasyon.asseco-see.com.tr/msu/api/v2/post/sale3d/${props.token}` : `https://merchantsafeunipay.com/msu/api/v2/post/preauth3d/${props.token}`
  useEffect(() => {
		if (contentRef !== null) {
			contentRef.submit();
		}
  }, [contentRef]);

	useEffect(()=> {
		let paratikatemp = localStorage.getItem("paratika");
		setParatikaObject(JSON.parse(paratikatemp));
	},[])

  return (
    <div id="3d-form-container">
			{paratikaObject !== null && (
      <form target="_self" id="3dForm" action={paymentUrl} method="POST" ref={setContentRef}>
        <input type="hidden" id="expiryMonth" name="expiryMonth" value={paratikaObject.expiryMonth} />
        <input type="hidden" id="expiryYear" name="expiryYear" value={paratikaObject.expiryYear} />
        <input type="hidden" id="pan" name="pan" value={paratikaObject.pan} />
        <input type="hidden" id="installmentCount" name="installmentCount" value="1" />
        <input type="hidden" id="amount" name="amount" value="1.00" />
        <input type="hidden" id="cvv" name="cvv" value={paratikaObject.cvv} />
        <input type="hidden" id="cardOwner" name="cardOwner" value={paratikaObject.cardOwner} />
      </form>
			)}
    </div>
  );
};
export default Paratika;
