import React from 'react';
import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff'
  }
}));

export default function Backdrop({ children, loading }) {
  const classes = useStyles();

  return (
    <>
      {children}
      <MuiBackdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </MuiBackdrop>
    </>
  );
}
