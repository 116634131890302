import axiosInstance from '../helpers/axiosHelper';

export async function getProductPaymentPlan(data) {
  return await axiosInstance({
    url: 'PaymentPlan/GetProductPaymentPlan',
    data,
    method: 'post'
    // credentials: 'same-origin',
  });

  // return MockPaymentPlans;
}
