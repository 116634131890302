export const DRNSGHTDLK = {
	header: {
		left: {
			authorizedAgencyLogo: false,
		},
		right: {
			phoneNumber: '0216 570 07 66',
			partnerLogo: true,
		},
	},
};
