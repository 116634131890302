// @ts-nocheck
// @ts-ignore
import { Backdrop, Box, Grid, makeStyles, Container, Typography } from '@material-ui/core';
import React, { forwardRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../providers/AppProvider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    backgroundColor: 'transparent'
  },
  overlayIcon: {
    width: '100%',
    height: '100%',
    background:
      ' rgba(255, 255, 255, 0.4) url(https://static.koalay.com/Images/KoalayApp/dask/hourglass.gif) no-repeat   center',
    backgroundSize: '60px',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9
  },
  backButton: {
    textTransform: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

const Panel = forwardRef((props, ref) => {
  const { children, title, icon, loading, goBack, style } = props;
  const { isMobile } = useContext(AppContext);
  const classes = useStyles();

  return (
    <Container
      style={{
        boxShadow: '0 0 10px #ddd',
        padding: isMobile ? '15px' : '30px',
        backgroundColor: 'rgba(255,255,255,.6)',
        marginBottom: isMobile ? '15px' : '30px',
        ...style
      }}
      innerRef={ref}
    >
      <Box
        component="div"
        style={{
          display: 'flex',
          marginBottom: title && 36,
          alignItems: 'center'
        }}
      >
        <Grid container>
          {title && (
            <Typography
              variant="h6"
              style={{
                fontSize: isMobile ? 18 : 22,
                fontWeight: 'bold',
                color: '#0055a5',
                textAlign: 'left'
              }}
            >
              {icon && (
                <FontAwesomeIcon
                  style={{
                    marginRight: 8
                  }}
                  size="lg"
                  icon={icon}
                />
              )}
              {title}
            </Typography>
          )}
        </Grid>
      </Box>

      {children}
      {isMobile && goBack && (
        <>
          <br />
          <Button className={classes.backButton} startIcon={<ChevronLeftIcon onClick={goBack} />} onClick={goBack}>
            Önceki Adıma Geri Dön
          </Button>
        </>
      )}
      <Backdrop className={classes.backdrop} open={loading || false}>
        <div className={classes.overlayIcon} />
      </Backdrop>
    </Container>
  );
});

export default Panel;
