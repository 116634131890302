/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useContext, useState, useEffect } from "react";
import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { Button, Divider, Grid, TextField, Typography,RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import Autocomplete from "../../../../../../components/Autocomplete/Autocomplete";
import { getCities, getDistricts, getTowns, getVillages } from "../../../../../../apiServices/generalService";

import { Panel } from "../../../../../../components/Panel";
import UAVTFind from "./components/UAVTFind";
import { Box } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { DaskHomeContext } from "../../context/DaskHomeContext";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AppContext } from "../../../../../../providers/AppProvider";
import { KoalayButton } from "../../../../../../components/KoalayButton";
import { Controller } from "react-hook-form";
import StreetSelect from "./components/StreetSelect";
import BuildingSelect from "./components/BuildingSelect";
import IndependentPartSelect from "./components/IndependentPartSelect";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";

import Modal from "../../../../../../components/Modal/Modal";
import WarningIcon from "@material-ui/icons/Warning";
import { useFormContext } from "react-hook-form";

const defaultProps = {
  variant: "outlined",
  size: "small",
  fullWidth: true,
};

const UAVTAddressSection = ({ completeSection, getNewOffer, prevUavt }) => {
  const { isMobile, globalLoading } = useContext(AppContext);
  const { addressOptions, setAddressOptions } = useContext(DaskHomeContext);
  const { enqueueSnackbar } = useSnackbar();
  const { formState, control, watch, trigger, setValue } = useFormContext();
  const [uavtFilled, setUavtFilled] = useState(false);
  const [uavtCode, setUavtCode] = useState(prevUavt);
	const [entryType, setEntryType] = useState("uavt");
	const [isUavt, setIsUavt] = useState(true)
  const { cities, towns, villages, districts } = addressOptions || {};
  const { errors } = formState;
  const { city, village } = watch("newAddress") || {};

  const styleProps = { style: { width: isMobile ? "100%" : "50%" } };

  useEffect(() => {
    async function fillCities() {
      const _cities = (await getCities()).data;

      setAddressOptions({ ...addressOptions, cities: _cities });
    }
    cities.length === 0 && fillCities();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                   STATES                                   */
  /* -------------------------------------------------------------------------- */

  /**
   * UAVT bilip bilmediğinin tutulduğu state, UAVTFind'dan geliyor
   */
  /**
   * UAVT'den gelen adresin geçici olarak tutulduğu state. GetNewOffer'da ihtiyaç oluyor
   */
  const [uavtAddress, setUavtAddress] = useState(undefined);

  /**
   * Müşterinin girdiği adres ile UAVT'den gelen adres uyuşmuyorsa gösterilcek Modal'ın visibility state'i
   */

  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */

  const newAddress = watch("newAddress");

  const { goPreviousSection, loading } = useContext(DaskHomeContext);

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  const cityOnChange = (onChange) => async (_cityCode, cityObj) => {
    const resettedAddressForCity = {
      ...addressOptions,
      towns: [],
      districts: [],
      villages: [],
    };
    setAddressOptions(resettedAddressForCity);

    setValue('newAddress', { district: null,  village: null,  town: null, block: '', plot: '', lot: '', page: '' } ); 


    if (_cityCode) {
      const _towns = (await getTowns(_cityCode)).data;
      setAddressOptions({ ...resettedAddressForCity, towns: _towns });
    }

    onChange(cityObj);
  };

  /**
   * Handle town change for re-fill villages
   * Set directly village if villages have one option
   * @param {*} _townCode-> new town value
   * @param {*} onChange-> callBack from hook-form
   */
  const townOnChange = (onChange) => async (_townCode, townObj) => {
    const resettedAddressForTown = {
      ...addressOptions,
      districts: [],
      villages: [],
    };
    setAddressOptions(resettedAddressForTown);
    setValue("newAddress.village", undefined);
    setValue("newAddress.district", undefined);
    if (_townCode) {
      const _villages = (await getVillages(_townCode)).data;

      setAddressOptions({
        ...resettedAddressForTown,
        villages: _villages,
      });
    }
    onChange(townObj);
  };

  /**
   * Handle village change for re-fill districts
   * @param {*} _townCode-> new town value
   * @param {*} onChange-> callBack from hook-form
   */
  const villageOnChange = (onChange) => async (_villageCode, villageObj) => {
    setAddressOptions({
      ...addressOptions,
      districts: [],
    });
    setValue("newAddress.district", undefined);
    if (_villageCode) {
      const _district = (await getDistricts(_villageCode)).data;

      setAddressOptions({
        ...addressOptions,
        districts: _district,
      });
    }
    
    onChange(villageObj);
  };

  function getAddressInfo(_address) {
    return [
      _address?.city?.Text || "",
      _address?.town?.Text || "",
      _address?.village?.Text || "",
      _address?.district?.Text || "",
    ].join(",");
  }

  /**
   * UAVT'den gelen adres ile kullanıcının girdiği adresin kontrol edilmesi
   */
  function isEqualUavtAndCurrentAddress(uavtAddress) {
    const uavtCompareModel = {
      city: uavtAddress.city?.Value,
      town: uavtAddress.town?.Value,
      village: uavtAddress.village?.Value,
      district: uavtAddress.district?.Value,
    };

    const currentAddressCompareModel = {
      city: newAddress.city?.Value,
      town: newAddress.town?.Value,
      village: newAddress.village?.Value,
      district: newAddress.district?.Value,
    };

    return isEqual(uavtCompareModel, currentAddressCompareModel);
  }

  /**
   * Handle find UAVT
   */
  const handleUavtAddress = (_uavtAddress) => {
    /**
     * Apiden dönen model proptertyleri büyük harf olduğu için mapleme yapıldı
     */
		 setUavtCode(localStorage.getItem("uavtCode"))
    setUavtFilled(true);
    const mappedUavtAddress = {
      city: _uavtAddress.City !== undefined ? _uavtAddress.City : _uavtAddress.city,
      town: _uavtAddress.Town !== undefined ? _uavtAddress.Town : _uavtAddress.town,
      village: _uavtAddress.Village !== undefined ? _uavtAddress.Village : _uavtAddress.village,
      district: _uavtAddress.District !== undefined ? _uavtAddress.District : _uavtAddress.district,
      street: _uavtAddress.Street !== undefined ? _uavtAddress.Street : _uavtAddress.street,
      independentPart: {
        Text: _uavtAddress.InnerDoorNum !== undefined ? _uavtAddress.InnerDoorNum : _uavtAddress.independentPart.Text,
        Value: _uavtAddress.AddressNum !== undefined ? _uavtAddress.AddressNum : _uavtAddress.independentPart.Value,
      },
      building: _uavtAddress.Building !== undefined ? _uavtAddress.Building : _uavtAddress.building,
      block: _uavtAddress.Block !== undefined ? _uavtAddress.Block : _uavtAddress.block,
      plot: _uavtAddress.Plot !== undefined ? _uavtAddress.Plot : _uavtAddress.plot,
      lot: _uavtAddress.Lot !== undefined ? _uavtAddress.Lot : _uavtAddress.lot,
			addressNum : _uavtAddress.AddressNum !== undefined ? _uavtAddress.AddressNum : _uavtAddress.independentPart.Value,
      page: _uavtAddress.page !== undefined ? _uavtAddress.page : ''
    };
    setUavtAddress({ ...mappedUavtAddress });

    setValue("newAddress", { ...mappedUavtAddress });
  };

  const onComplete = () => {
    completeSection();
  };

  /**
   * Kullanıcının girdiği adresiyle uyuşmayan UAVT adresi ile tekrardan teklif alınması
   * QuoteSummary stepine dönülmesi
   */
  const getNewOfferWithUavt = async () => {
    const newUAVTAddressModel = { ...newAddress, ...uavtAddress };

    await getNewOffer(newUAVTAddressModel);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
	const handleEntryTypeChange = (e) => {
    setEntryType(e.target.value);
  };
	useEffect(() => {
		if (!isUavt && localStorage.getItem("uavtCode") !== 'undefined') {
			const newAdress = {
				city: "",
				town: "",
				village: "",
				district: "",
				street: "",
				independentPart: "",
				building: "",
				block: "",
				plot: "",
				lot: "",
				page: "",
			};
			setValue("newAddress", { ...newAdress });

		}else if(isUavt && localStorage.getItem("uavtCode") !== "undefined"){
			handleUavtAddress(uavtAddress);
		}
	}, [isUavt])
	
	useEffect(()=>{
    localStorage.setItem('entryType', entryType);
		if (entryType=="newAddress") {
			setIsUavt(false)
		}else{
			setIsUavt(true)
		}
	},[entryType])

  return (
    <>
		  <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={entryType}
        onChange={handleEntryTypeChange}
        row
      >
        <FormControlLabel value="uavt" control={<Radio />} label="UAVT (Adres) Kodu ile Devam Et" />
        <FormControlLabel value="newAddress" control={<Radio />} label="Adresini Girerek Devam Et" />
      </RadioGroup>
			<br></br>
			<br></br>
      <Grid container spacing={3}>
        {isUavt ? (
          <Grid item xs={12}>
            <UAVTFind handleUavtAddress={handleUavtAddress} prevUavt={uavtCode}/>
          </Grid>
        ) : ""}
        {!isUavt ? (
          <>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    name="newAddress.city"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        onChange={cityOnChange(onChange)}
                        error={errors?.newAddress?.city ? true : false}
                        options={cities}
                        value={value}
                        textValue={newAddress?.City?.Text}
                        disabled={globalLoading}
                        label="İl"
                        size="small"
                        fullwidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="newAddress.town"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        disabled={globalLoading || towns?.length > 0 ? false : true}
                        value={value}
                        textValue={newAddress?.Town?.Text}
                        error={errors?.newAddress?.town ? true : false}
                        onChange={townOnChange(onChange)}
                        Input
                        label="İlçe"
                        options={towns}
                        size="small"
                        fullwidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="newAddress.village"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        disabled={villages?.length > 0 ? false : true}
                        value={value}
                        error={errors?.newAddress?.village ? true : false}
                        onChange={villageOnChange(onChange)}
                        label="Belde"
                        options={villages}
                        textValue={newAddress?.Village?.Text}
                        size="small"
                        fullwidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="newAddress.district"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        disabled={globalLoading || districts?.length > 0 ? false : true}
                        value={value}
                        textValue={newAddress?.District?.Text}
                        error={errors?.newAddress?.district ? true : false}
                        onChange={(val, obj) => onChange(obj)}
                        label="Mahalle"
                        options={districts}
                        fullwidth
                        size="small"
                      />
                    )}
                  />
                </Grid>

                {/**************** Street *******************/}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.street"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <StreetSelect
                        {...defaultProps}
                        value={value}
                        onChange={(val, obj) => onChange(obj)}
                        error={errors?.newAddress?.street ? true : false}
                        district={newAddress?.district?.Value}
                        disabled={!newAddress?.district}
                        textValue={newAddress?.street?.Text}
                        fullwidth
                        label="Cadde/Sokak/Bulvar/Meydan"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {/**************** Building *******************/}

                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.building"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <BuildingSelect
                        {...defaultProps}
                        label="Bina no"
												isUavt={false}
                        street={newAddress?.street?.Value}
                        textValue={newAddress?.building?.Text}
                        error={errors?.newAddress?.building ? true : false}
                        disabled={!newAddress?.street}
                        fullwidth
                        value={value}
                        onChange={(value, model) => {
                          onChange(model);

                          if (model) {
                            setValue("newAddress.block", model?.Block);
                            setValue("newAddress.plot", model?.Plot);
                            setValue("newAddress.lot", model?.Lot);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/**************** independentPart *******************/}
                  <Controller
                    control={control}
												isUavt={false}
												defaultValue=""
                    name="newAddress.independentPart"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <IndependentPartSelect
                        {...defaultProps}
                        label="Daire no"
												isUavt={false}
                        building={newAddress?.building?.Value}
                        disabled={!newAddress?.building}
                        textValue={newAddress?.independentPart?.Text}
                        error={errors?.newAddress?.independentPart ? true : false}
                        fullWidth
                        value={value}
                        onChange={(val, obj) => onChange(obj)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...defaultProps}
                    disabled={true}
                    value={newAddress?.independentPart?.Value || ""}
                    label="UAVT(Adres) no"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.block"
                    render={({ field }) => <TextField {...field} {...defaultProps} label="Bina Ada No" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.plot"
                    render={({ field }) => <TextField {...field} {...defaultProps} label="Bina Pafta No" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.lot"
                    render={({ field }) => <TextField {...field} {...defaultProps} label="Bina Parsel No" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.page"
                    render={({ field }) => (
                      <TextField {...field} {...defaultProps} value={newAddress?.page || ""} label="Bina Sayfa No" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
				  {uavtCode !== 'undefined' && isUavt ? (
          <>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={newAddress?.city?.Text || ""}
                    label="İl"
                    {...defaultProps}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...defaultProps} disabled={true} value={newAddress?.town?.Text || ""} label="İlçe" />
                </Grid>
                {newAddress?.village && (
                  <Grid item xs={12}>
                    <TextField
                      {...defaultProps}
                      disabled={true}
                      value={newAddress?.village?.Text || ""}
                      label="Belde"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    {...defaultProps}
                    disabled={true}
                    fullWidth
                    value={newAddress?.district?.Text || ""}
                    label="Mahalle"
                  />
                </Grid>

                {/**************** Street *******************/}
                <Grid item xs={12}>
                <TextField
                    {...defaultProps}
                    disabled={true}
                    fullWidth
                    value={newAddress?.street?.Text || ""}
                    label="Cadde/Sokak/Bulvar/Meydan"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {/**************** Building *******************/}
                  <TextField
                    {...defaultProps}
                    disabled={true}
                    fullWidth
                    value={newAddress?.building?.Text || ""}
                    label="Bina no"                  
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/**************** independentPart *******************/}
                  <TextField
                    {...defaultProps}
                    disabled={true}
                    fullWidth
                    value={newAddress?.independentPart?.Text || ""}
                    label="Daire no"                  
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.block"
                    render={({ field }) => <TextField {...field} {...defaultProps} label="Bina Ada No" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.plot"
                    render={({ field }) => <TextField {...field} {...defaultProps} label="Bina Pafta No" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.lot"
                    render={({ field }) => <TextField {...field} {...defaultProps} label="Bina Parsel No" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newAddress.page"
                    render={({ field }) => (
                      <TextField {...field} {...defaultProps} value={newAddress?.page || ""} label="Bina Sayfa No" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      <br />
      <Divider />
      <br />
    </>
  );
};

export default UAVTAddressSection;
