import React from 'react';

const BrokerAgreement = () => {
  return (
    <div>
      <p>
        <b>Koalay’ı sigorta brokeri olarak yetkilendirmek ne anlama gelir?</b> <br />
        Sigorta brokerleri, müşterilerinden yazılı, sözlü veya elektronik olarak brokerlik yetkilendirmesi almadan
        sigorta teklifleri sunamaz. Brokerler yönetmeliğine uygun şekilde Koalay’ı brokeriniz olarak yetkilendirdiğiniz
        zaman, Koalay’ın size karşılaştırmalı fiyatları sunabilmesine, size danışmanlık yapabilmesine, ve onayladığınız
        takdirde poliçe satın almanıza aracılık etmesine izin vermiş olursunuz.
        <br />
        <br />
        Koalay, sadece yetki verilen ürünler için, sigorta veya reasürans sözleşmesi yaptırmak isteyenleri temsil
        ederek, bu sözleşmelerin yaptırılacağı sigorta şirketlerinin seçiminde tamamen tarafsız ve bağımsız davranarak,
        teminat almak isteyen kişilerin hak ve menfaatlerini gözeten temsilcisidir.
        <br />
        <br />
        Koalay’ı broker olarak yetkilendirmek, Koalay’a karşı herhangi bir parasal sorumluluğunuzu doğurmaz. Koalay,
        kredi kartı bilgilerinizi saklamaz. Onaylamadığınız bir işlem için kredi kartınızdan tahsilat yapmak, Koalay’ın
        yapabileceği bir işlem değildir.
      </p>
      <br />
      <hr />
      <br />
      <p>
        <b>Brokerlik Sözleşmesi</b>
        <br />
        "Koalay Com Sigorta ve Reasürans Brokerliği Anonim Şirketi (“Broker”, Lisans Numarası: 1166-18740), usulüne
        uygun ruhsat ve ilgili mevzuat hükümlerine göre tesis edilen yetki ile, sigorta veya reasürans sözleşmesi
        yaptırmak isteyenleri temsil eden, bu sözleşmelerin yaptırılacağı şirketlerin seçiminde tamamen tarafsız ve
        bağımsız davranarak teminat almak isteyen kişilerin hak ve menfaatlerini gözeten, sigorta sözleşmesi yapmak
        isteyenlerle sigorta şirketlerini bir araya getiren ve sigorta sözleşmelerine aracılık eden bir kuruluştur.
        <br />
        <br />
        Müşteri, işbu Brokerlik Sözleşmesi’nin (“Sözleşme”) kabulü ile Broker’i, sigorta şirketlerinden adına teklif
        alınması; poliçelendirme işlemlerinin başlatılması ve poliçelerin sigortacılar tarafından düzenlenmesinin
        ardından teslimi konusunda yetkilendirmekte ve sigorta ile ilgili her konuda tek yetkili sigorta brokeri olarak
        tayin etmektedir.
        <br />
        <br />
        Poliçe prim tahsilâtları sigorta şirketlerinin sanal posları aracılığıyla yapılmaktadır ve Broker’in bu hususta
        bir sorumluluğu yoktur. Broker, sigorta sözleşmesinin akdinden önceki hazırlık çalışmalarını yaparak;
        gerektiğinde bu anlaşmaların uygulanmasında yardımcı olacaktır. İşbu Sözleşme sigortalılar ile sigorta
        şirketlerine atfedilen yükümlülüklerin Broker’e atfedilmesi şeklinde yorumlanamaz. Sigorta teminatı, sigorta
        poliçesinin mutabık kalınan koşullarda Broker tarafından düzenlenmesi, peşin ve/veya kredi kartına taksitli
        olarak kararlaştırılan sigorta primi miktarının ödenmesi ile başlar ve poliçe özel ve genel şartlarındaki
        koşullarla geçerli olur.
        <br />
        <br />
        Broker, sigorta tazminatı veya tazminata ilişkin avans ödeyemez. Broker sigorta teminatı vermemekte sadece
        sigorta şirketleri ile sigorta ettiren/sigortalı arasında anlaşma yapılmasına aracılık etmektedir. Poliçeler
        Müşteri tarafından basılmak sureti ile teslim alınmış olur. Poliçe asıllarının Müşteri tarafından ayrıca
        istenmesi durumunda, poliçe asılları Müşteri’nin adresine gönderilir. Müşterilerin kendi adına ve sigortalı
        olmaya haiz diğer kişiler adına işlem yapabilmeleri mümkündür.”
      </p>
    </div>
  );
};

export default BrokerAgreement;
