/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { AppContext } from '../../providers/AppProvider';
import axiosInstance from './../../helpers/axiosHelper';

/**
 *! Tüm axios çağrılarında araya girerek globalLoading'i yönetir
 * @param {*} children
 * @returns children
 */
const GlobalMessageHandler = ({ children }) => {
  const { setGlobalLoading } = useContext(AppContext);

  React.useEffect(() => {
    axiosInstance.interceptors.request.use(
      function (config) {
        setGlobalLoading(true);
        return config;
      },
      function (error) {
        setGlobalLoading(false);
      }
    );

    axiosInstance.interceptors.response.use(
      function (response) {
        setGlobalLoading(false);
        if (response.data.errorCode) {
          return Promise.reject(response.data.errorCode);
        }
        return response;
      },
      function (error) {
        setGlobalLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return <>{children}</>;
};

export default GlobalMessageHandler;
