import { Container, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../providers/AppProvider';
import CookieModal from '../Cookie/Cookie';
import Header from './../Header/Header';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 768,
    padding: '20px 20px 60px 20px',
    minHeight: 'calc(100% - 80px)',

    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100% - 60px)',
      padding: '10px 10px 60px 10px',
      maxWidth: '100%'
    }
  },
  cengiz: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'initial'
    }
  },
  footer: {
    left: 0,
    bottom: 0,
    marginTop: -45,
    padding: '12px 0',
    background: '#fff',
    fontSize: '11px',
    width: '100%',
    boxShadow: '0 0 10px #ddd',
    color: '#666',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    height: 45
  }
}));

const Layout = ({ children }) => {
  const { isMobile } = useContext(AppContext);
  const { cengiz, footer, container } = useStyles();
	const date = new Date()
  return (
    <>
      <Header />
      <Container className={container}>{children}</Container>

      <img
        className={cengiz}
        alt="cengiz"
        id="cengiz"
        src="https://static.koalay.com/Images/KoalayApp/Desktop/Marketing/ipad-koalay.png"
      />
      <CookieModal />
      <div className={footer}>
        <span style={{ textAlign: 'center' }}>
          Tüm sigorta hizmetleri Koalay Com Sigorta Reasürans Brokerliği A.Ş.
          tarafından sağlanmaktadır.
        </span>
        {!isMobile && (
          <span style={{ marginLeft: '4rem' }}>
            {' '}
            Koalay | Tüm Hakları Saklıdır {date.getFullYear()} ©
          </span>
        )}
      </div>
    </>
  );
};

export default Layout;
