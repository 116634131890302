// @ts-nocheck
import { TextField } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ value }) => {
        onChange(value);
      }}
      format="(###) ### ####"
    />
  );
}

function PhoneNumberInput(props) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
  );
}

export default PhoneNumberInput;
