import { Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useContext } from 'react';
import { AppContext } from '../../providers/AppProvider';

/**
 * ! fullwidth if its mobile
 * ! space-between if its mobile
 */
export const KoalayButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    fontWeight: 600,
    padding: 10,

    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.koalayGreenColor,
      width: '100%',
      '&:hover': {
        backgroundColor: '#679727'
      }
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'center'
  }
}))(({ color = 'primary', ...rest }) => {
  const { isMobile } = useContext(AppContext);
  return (
    <Button
      {...rest}
      color={isMobile ? 'default' : color}
      variant="contained"
    />
  );
});
