/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { TextField } from '@material-ui/core';

import React, { useCallback } from 'react';
import OrjNumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, inputProps, ...other } = props;

  return (
    <OrjNumberFormat
      {...other}
      {...inputProps}
      getInputRef={inputRef}
      onValueChange={({ value }) => {
        onChange(value);
      }}
    />
  );
}

const NumberFormat = React.forwardRef(({ format, inputProps, ...rest }, ref) => {
  return (
    <TextField
      {...rest}
      inputRef={ref}
      InputProps={{
        ...inputProps,
        inputComponent: useCallback(props => <NumberFormatCustom {...props} {...inputProps} format={format} />, [
          format,
          //TODO inputProps'u komple geçince sürekli re-render oluyor. object referanstan dolayı
          inputProps?.readOnly
        ])
      }}
    />
  );
});

export default NumberFormat;
