import { Checkbox, withStyles } from '@material-ui/core';
import React from 'react';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#8dc63f'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    paddingLeft: 0
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const GreenCheckbox = props => {
  return <StyledCheckbox {...props} />;
};

export default GreenCheckbox;
