import {
  FormControlLabel as MuiFormControlLabel,
  RadioGroup,
  Radio,
  withStyles,
  styled,
  withTheme
} from '@material-ui/core';
import React from 'react';
import { PolicyType } from '../../../constants';

const GreenRadio = withStyles((props) => ({
  root: {
    '&$checked': {
      color: props.palette.secondary.main
    }
  },
  checked: {}
}))((props) => <Radio color="default" {...props} />);

export const FormControlLabel = styled(withTheme(MuiFormControlLabel))(
  (props) => ({
    border: `2px solid ${props.theme.palette.primary.main}`,
    padding: '0.5vw 2vw 0.5vw 1vw',
    color: props.checked ? '#fff' : props.theme.palette.primary.main,
    backgroundColor: props.checked ? props.theme.palette.primary.main : '#fff',
    borderRadius: '5px',
    width: '48%',
    marginRight: 0,
    textAlign: 'center',
    display: 'inline'
  })
);

const PolicyTypeRadioGroup = ({ value, onChange, disabled }) => {
  return (
    <RadioGroup
      row
      style={{ marginLeft: 10, justifyContent: 'space-between' }}
      value={value}
      onChange={(e) => {
        onChange(parseInt(e.target.value));
      }}
    >
      <FormControlLabel
        checked={value === PolicyType.NEW}
        disabled={disabled}
        value={PolicyType.NEW}
        control={<GreenRadio />}
        label={
          <>
            Yeni Poliçe
            <div style={{ fontSize: 12 }}>
              Adresinize ilk kez DASK poliçesi yaptırıyorsanız
            </div>
          </>
        }
      />
      <FormControlLabel
        checked={value === PolicyType.RENEWAL}
        disabled={disabled}
        value={PolicyType.RENEWAL}
        control={<GreenRadio />}
        label={
          <>
            Yenileme Poliçe
            <div style={{ fontSize: 12 }}>
              Adresinize önceden DASK poliçesi yaptırdıysanız
            </div>
          </>
        }
      />
    </RadioGroup>
  );
};

export default PolicyTypeRadioGroup;
