/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getQuatationDetail, purchase, purchaseWAB } from "../../../../apiServices/sideProductService";
import { Panel } from "../../../../components/Panel";
import PaymentHeader from "./components/PaymentHeader";
import CreditCardForm from "./components/CreditCardForm";
import FlatAdditionalInfoForm from "./components/FlatAdditionalInfoForm";
import PersonalInfoForm from "./components/PersonalInfoForm";
import PaymentContext from "./context/PaymentContext";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { useContext } from "react";
import { AppContext } from "../../../../providers/AppProvider";
import { UnhandledErrorText } from "../../constants";
import { validateCreditCard } from "../../../../helpers/utils";
import { Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Paratika from "./Paratika";
import CustomIframe from "./CustomIframe";
import { triggerGAEvent } from "../../../../helpers/gaEvent";
import { gaEvents } from "../../../../data/gaEvents";

const StompJs = require("@stomp/stompjs");

const PaymentPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { search } = useLocation();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const extRefHash = URL_PARAMS.get('extRefHash');
  const { funnelData, funnelType, setFunnelType, isMobile, setMainLogoHref, isRenewal } = useContext(AppContext);

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const [isInit, setIsInit] = useState(false);
  const [customer, setCustomer] = useState(undefined);
  const [quote, setQuote] = useState(undefined);
  const [paymentData, setPaymentData] = useState(undefined);
  const [addressData, setAddressData] = useState(undefined);
  const [customerData, setCustomerData] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(undefined);
  const [token, setToken] = useState(null);
  const [paratikaObject, setParatikaObject] = useState(null);
  const [isIssueSendable, setIsIssueSendable] = useState(false);
  const [creditCardOwnershipVisible, setCreditCardOwnershipVisible] = useState(false);
  const form = useForm({ mode: "onChange" });
  const { handleSubmit, setValue, formState, trigger, getValues } = form;
  const { isValid } = formState;
  let queueName;

  const client = new StompJs.Client({
    brokerURL: "wss://q.fincase.tech:15674/ws",
    connectHeaders: {
      login: "koalay",
      passcode: "koalay",
    },
    heartbeatIncoming: 3000,
    heartbeatOutgoing: 3000,
    reconnectDelay: 0,
  });
  const headers = {
    "auto-delete": true,
    "x-expires": 3600000,
  };

  const sendIssue = async () => {
    try {
      const customer = customerData;
      const address = addressData;
      const payment = paymentData.identityNumber ? paymentData : {
        ...paymentData,
        identityNumber: customer.identityNo
      };
      await validateBeforeSubmit();

      const paymentModel = {
        ProductGroupId: 3,
        Payment: { ...payment, TelNo: customer.mobilePhone },
        ProductId: quote.productId,
        QuotationUID: quote.quotationUID,
        TeklifTId: quote.teklifTId,
        Address: { ...address },
        Customer: { ...customer },
        functionalBranchId: 2,
      };
      setLoading(true);
      const res = await purchase(paymentModel);
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: 'TRY',
          transaction_id: quote.quotationID,
          value: quote.commission,
          items: [{
            item_list_id: `offer_counts_0`,
            item_list_name: `offer counts 0`,
            discount: null,
            index: 1,
            item_brand: quote.insurerName,
            item_category: 'DASK',
            item_id: quote.productId,
            item_name: quote.productName,
            premium: quote.grossPremium,
            price: quote.commission,
            quantity: 1
          }]
        },
        customer_data: {email: customerData.eMail, phone: '+90' + customerData.mobilePhone}
      })
			if (document.location.href.indexOf("Token") >= 0 || document.location.href.indexOf("referenceid") >= 0) {
				push(`/dask/thankyou${document.location.search.split("&")[0]}&teklifTId=${res.data.policy.teklifTId}`);
			} else if (document.location.href.indexOf("extRefHash") >= 0) {
        push(`/dask/thankyou?teklifTId=${res.data.policy.teklifTId}&extRefHash=${extRefHash}`);
      } else {
				push("/dask/thankyou?teklifTId=" + res.data.policy.teklifTId);
			}
    } catch (error) {
      enqueueSnackbar(error?.message || UnhandledErrorText, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const subscribeToSocket = () => {
    const { qGuid, teklifTId } = queryString.parse(search);
    queueName = `KoalayParatikaResult_${qGuid}`;
    client.activate();
    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect

      client.subscribe(
        queueName,
        (message) => {
          if (message && message.body !== "") {
						client.forceDisconnect();
						document.querySelectorAll('[role=presentation]')[0].getElementsByTagName('iframe')[0].remove();
            const body = JSON.parse(message.body);
            if (body.ErrorMessage === "") {
              setIsIssueSendable(true);
							setToken(null)
            } else {
              enqueueSnackbar(body.ErrorMessage, {
                variant: "error",
              });
							setToken(null)
            }
          }
        },
        headers
      );
    };
  };
  /* -------------------------------------------------------------------------- */
  /*                                   EFFECT                                   */
  /* -------------------------------------------------------------------------- */

  /**
   * initiliaze funnel data
   */
  useEffect(() => {
		triggerGAEvent(isRenewal ? gaEvents.RenewalCheckoutSeen : gaEvents.PolicyCheckoutSeen)
    if (funnelType !== 2) {
      setFunnelType(2);
    }
  }, []);

  useEffect(() => {
    setMainLogoHref("/#");
  }, [customer]);

  /**
   * getQuatationDetail after funnel data is not empty
   */
  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        const response = (await getQuatationDetail({ teklifTId, qGuid })).data.result;
        setCustomer(response.customer);
        setQuote(response.quotations[0]);
        setCreditCardOwnershipVisible(response.quotations[0]?.creditCardOwnershipVisible);
        initiliazePaymentOptions(response.quotations[0]?.paymentOptions);
        setAddress(response.address);
        setIsInit(true);
        initiliazeForm(response);
      } catch (error) {
        enqueueSnackbar(UnhandledErrorText, { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
    const { qGuid, teklifTId } = queryString.parse(search);
    funnelData && (teklifTId || qGuid) && init();
  }, [search, funnelData]);

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  const validateBeforeSubmit = () => {
    !isValid && trigger();

    if (!validateCreditCard(getValues("payment.CardNo"))) {
      return Promise.reject({
        message: "Lütfen geçerli bir kredi kartı numarası giriniz.",
      });
    }

    return isValid ? Promise.resolve(true) : Promise.reject({ message: "Lütfen zorunlu alanları kontrol ediniz." });
  };

  /**
   * Set default form values from getQuatationDetail response
   */
  const initiliazeForm = ({ address }) => {
    setValue("address", {
      block: address.block,
      plot: address.plot,
      lot: address.lot,
      page: address.page,
    });
  };

  const initiliazePaymentOptions = (options) => {
    const paymentPlans = (options || []).map((item) => {
      return { Value: item.value, Text: item.text };
    });
    setPaymentPlan(paymentPlans);
  };

  useEffect(() => {
    if (token !== null) {
      subscribeToSocket();
    }
  }, [token]);
  useEffect(() => {
    if (paratikaObject === null) {
      setToken(null);
    }
  }, [paratikaObject])

  const onSubmit = async ({ payment, address: formAddress, customer: formCustomer }) => {
    setPaymentData(payment);
    setAddressData(address);
    setCustomerData(customer);
    const paratikaObject = {
      quoteId: quote.quotationUID,
      teklifTId: quote.teklifTId,
      paymentPageUrl: document.location.origin + "/dask/PaymentPopup/Index?jt=" + customer.userGuid,
      branchType: 2,
      paymentContextInfo: {
        expiryMonth: payment.ExpMonth <= 9 ? "0" + payment.ExpMonth : payment.ExpMonth,
        expiryYear: payment.ExpYear,
        pan: payment.CardNo,
        cvv: payment.Cvc2,
        cardOwner: payment.CardOwnerName,
        domainName: "",
      },
    };
    if (quote.needsSecurePayment) {
      purchaseWAB(paratikaObject, customer.userGuid, (response) => {
        setToken(response.data.Result.token);
      });

      setParatikaObject({
        cardOwner: payment.CardOwnerName,
        pan: payment.CardNo,
        expiryMonth: payment.ExpMonth <= 9 ? "0" + payment.ExpMonth : payment.ExpMonth,
        expiryYear: payment.ExpYear,
        cvv: payment.Cvc2,
        teklifTId: quote.teklifTId,
        quoteId: quote.quotationUID,
      });
      localStorage.setItem(
        "paratika",
        JSON.stringify({
          cardOwner: payment.CardOwnerName,
          pan: payment.CardNo,
          expiryMonth: payment.ExpMonth <= 9 ? "0" + payment.ExpMonth : payment.ExpMonth,
          expiryYear: payment.ExpYear,
          cvv: payment.Cvc2,
          teklifTId: quote.teklifTId,
          quoteId: quote.quotationUID,
        })
      );
    } else {
      setIsIssueSendable(true);
    }
  };
  const goPreviousSection = () => {
    push("/home");
  };
  useEffect(() => {
    if (isIssueSendable) {
      sendIssue();
    }
  }, [isIssueSendable]);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <PaymentContext.Provider value={{ customer, quote, address, isInit, paymentPlan, setPaymentPlan }}>
      <PaymentHeader logoSrc={quote?.insurerElwinName} cost={quote?.grossPremium} />

      <Panel loading={loading} goBack={goPreviousSection}>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <PersonalInfoForm form={form} />
                </Grid>
                <Grid item xs={12}>
                  <FlatAdditionalInfoForm form={form} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CreditCardForm form={form} creditCardOwnershipVisible={creditCardOwnershipVisible} />
            </Grid>
          </Grid>
        </form>
        {!isMobile && (
          <>
            <br />{" "}
            <Button startIcon={<ChevronLeftIcon />} onClick={goPreviousSection}>
              Önceki Adıma Geri Dön
            </Button>
          </>
        )}
      </Panel>
      {paratikaObject !== null && (
        <Modal open={token !== null}>
          <CustomIframe>
            <Paratika paratikaObject={paratikaObject} token={token}></Paratika>
          </CustomIframe>
        </Modal>
      )}
    </PaymentContext.Provider>
  );
};

export default PaymentPage;
