import React from 'react';

const KvkkInformation = () => {
  return (
    <div>
      <p>
        Kullanıcılarımızın kişisel verilerini güvence altına almak,
        Koalay.com’un sunduğu hizmetin en önemli bileşenlerinden biridir.
        Koalay.com’un hizmet kalitesi standartlarına ek olarak, özel hayatın
        gizliliği, temel hak ve özgürlükler ile kişisel verilerin güvenliği
        artık kanunen de güvence altında.
      </p>

      <p>
        Koalay.com olarak sizi 6698 sayılı Kişisel Verilerin Korunması Kanunu
        hakkında bilgilendirmek isteriz.
      </p>

      <b>Kişisel Verilerin Korunması Kanunu Hakkında Bilgilendirme</b>
      <br />
      <br />
      <p>
        İşbu Bilgilendirme’nin amacı, Koalay Com Sigorta ve Reasürans Brokerliği
        Anonim Şirketi tarafından yönetilmekte olan{' '}
        <a
          href='https://www.koalay.com/ '
          target='_blank'
          without
          rel='noopener noreferrer'
        >
          {' '}
          https://www.koalay.com/
        </a>{' '}
        adresinde yer alan internet sitesinin kullanımı sırasında elde edilen ve
        üçüncü kişilerden alınan kişisel verilerin kullanımına ilişkin olarak{' '}
        <a
          href='https://www.tbmm.gov.tr/kanunlar/k6698.html'
          target='_blank'
          without
          rel='noopener noreferrer'
        >
          {' '}
          6698 sayılı Kişisel Verilerin Korunması Kanunu
        </a>{' '}
        10. maddesi ile getirilen aydınlatma yükümlülüğünün yerine
        getirilmesidir. Koalay Com Sigorta ve Reasürans Brokerliği, işbu
        Bilgilendirme hükümlerini dilediği zaman site üzerinden yayımlamak
        suretiyle güncelleyebilir ve değiştirebilir. Koalay.com Sigorta ve
        Reasürans Brokerliği’nin yaptığı güncelleme ve değişiklikler site
        yayınlandığı tarihten itibaren geçerli olacaktır.
      </p>
      <br />
      <b> Veri Sorumlusu</b>
      <br />
      <br />
      <p>
        07.04.2016 tarihinde Resmi Gazete’de yayınlanarak yürürlüğe giren{' '}
        <a
          href='https://www.tbmm.gov.tr/kanunlar/k6698.html'
          target='_blank'
          without
          rel='noopener noreferrer'
        >
          {' '}
          6698 sayılı Kişisel Verilerin Korunması Kanunu
        </a>{' '}
        kapsamında şirketimiz Koalay Com Sigorta ve Reasürans Brokerliği, Veri
        Sorumlusu sıfatına sahiptir. Kişisel Verilerin Korunması Kanunu
        uyarınca, kişisel verileriniz; Veri Sorumlusu olarak Koalay Com Sigorta
        ve Reasürans Brokerliği tarafından aşağıda açıklanan kapsamda toplanacak
        ve işlenebilecektir.
      </p>
      <br />
      <b> Toplanan Kişisel Veriler</b>
      <br />
      <br />
      <ol>
        <li>
          <p>
            <b>Ad ve İletişim Bilgileri:</b> Ad, soyadı, cep telefonu, ev
            telefonu, iş telefonu, adresi, e-posta adresi, fatura bilgileri, TC
            kimlik numarası, kimlik, yabancı kimlik, pasaport, ehliyet
            fotokopisi ve benzer diğer belgeler.
          </p>
        </li>
        <li>
          <p>
            <b>Özel Nitelikli Kişisel Veri:</b> Nüfus ve ehliyet gibi belgelerde
            bulunan bilgiler, verilen hizmet kapsamında sağlanan sigorta
            poliçesi bağlamında gerekli olabilecek, daha önceki sigortalılık
            durumuna ilişkin, mesleki durumuna ilişkin, sağlık durumuna ilişkin
            ve/veya sigortalanan değerlere ilişkin bilgiler.
          </p>
        </li>
        <li>
          <p>
            <b>Görsel ve İşitsel Veri:</b>Fotoğraf, ses kayıtları, sigortalanan
            değerlere ilişkin fotoğraflar.
          </p>
        </li>
        <li>
          <p>
            <b>Kullanım Verileri:</b>Çeşitli yazılım ve teknolojik araçlar
            vasıtasıyla cihazlarınızdan toplanan veriler dahil ancak bunlarla
            sınırlı olmamak üzere Koalay Com Sigorta ve Reasürans Brokerliği’ni
            veya Çağrı Merkezlerini arama nedenleriniz, ses kayıtları, incelenen
            sigorta poliçe ve teklifleri, son ziyaret tarihi, ziyaret edilen
            internet siteleri, görüntülenen sayfa sayısı, ziyaret süresi, hizmet
            kullanımı sırasında oluşan hatalar ve benzeri sorunlar.
          </p>
        </li>
      </ol>
      <p>
        Müşteri ve/veya teklif alan olarak talep ettiğiniz işlemlerin
        gerçekleştirilebilmesi ve size uygun hizmetin sunulabilmesi için ihtiyaç
        duyulan kişisel verileriniz; web sitelerimiz üzerinden yapılmış olan
        dijital başvurular, çağrı merkezlerimiz, sosyal medya, müşteri
        görüşmeleri, sigorta şirketleri, Kimlik Paylaşım Sistemi, Adres Paylaşım
        Sistemi gibi kanallar aracılığıyla toplanmakta olup yasal süreler ve
        şartlar kapsamında saklanmaktadır. Elde ettiğimiz kişisel verileriniz
        yurtiçinde veya yurtdışında Koalay Com Sigorta ve Reasürans Brokerliği
        ya da bağlı kuruluşlarının, alt kuruluşlarının veya işbirliği içinde
        bulunduğu hizmet servis sağlayıcılarının tesisi bulunan başka bir ülkede
        depolanabilir ve işbu Bilgilendirme’deki amaçlar doğrultusunda ve bu
        amaçlarla orantılı olarak işlenebilir. İşbu Bilgilendirme kapsamında
        toplanan kişisel verileriniz burada yer alan hükümlere ve verilerin
        depolandığı ve işlendiği ülkede yürürlükte olan mevzuat kapsamında ve
        öngörülen güvenlik önlemleri dâhilinde işlenecektir.
      </p>
      <br />
      <b>Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri:</b>
      <br />
      <br />
      <p>
        Kişisel verileriniz,{' '}
        <a
          href='https://www.tsb.org.tr/default.aspx?pageID=654&yid=189'
          target='_blank'
          without
          rel='noopener noreferrer'
          class='prevent-sbn'
        >
          Sigortacılık Kanunu
        </a>{' '}
        ve ilgili diğer mevzuat kapsamında ürün ve hizmetlerle ilgili olarak
        gerçekleştirilecek her türlü iş ve işlemde işlem sahibini ve muhatabını
        belirlemek üzere kimlik, adres, vergi numarası ve diğer bilgilerinizin
        kaydedilmesi, kağıt üzerinde veya elektronik ortamda gerçekleştirilecek
        iş ve işlemlere dayanak olacak bilgi ve belgelerin düzenlemesi, talep
        edilen sigorta türüne göre sigortalanacak değere ilişkin gerekli bilgi
        ve belgelerin toplanması, sigorta poliçesi yenileme ve zeyil
        işlemlerinin yapılabilmesi, prim ödemelerine ilişkin olası sorunların
        haber verilmesi, ihtiyaç veya hasar halinde gerekli süreçlerin
        yürütülmesine destek olunması, ilgili mevzuat uyarınca adli ve idari tüm
        yetkili mercilerce öngörülen bilgi saklama, raporlama ve bilgilendirme
        yükümlülüklerine uyulması ve aramızdaki sözleşmelerin gereğinin yerine
        getirilmesi, sizlere daha iyi bir hizmet sağlamak, istatistiki bilgi
        toplamak ve derlemek, rızanız olması halinde
        ürün/hizmet/reklam/kampanyalar hakkında haber verilebilmesi ve size özel
        kampanya ve faydalar hazırlayabilmesi ve kullandırılabilmesi,
        memnuniyetinizi ölçebilmek için anket yapılabilmesi, çağrı merkezi
        hizmetlerinin sunulabilmesi ve ticari faaliyetlerini geliştirmek
        amaçlarıyla tarafımızca kullanılabilecektir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği ile iş ilişkisi içerisinde
        olan üçüncü gerçek veya tüzel kişiler ile yapılan sözleşmeler veya
        yürütülen faaliyetler ile yasal düzenlemelerden doğan yükümlülükler
        çerçevesinde hukuki ve ticari yükümlülüklerin gerçekleştirilmesi için
        Koalay Com Sigorta ve Reasürans Brokerliği tarafından iş
        ortağı/müşteri/tedarikçiler ile yapılan sözleşmelerden kaynaklanan
        yükümlülükleri ifa etme, hak tesis etme, hakları koruma, ticari ve
        hukuki değerlendirme süreçlerini yürütme, hukuki ve ticari risk
        analizleri yapma, hukuki uyum sürecini yürütme, mali işleri yürütme,
        yasal gereklilikleri yerine getirme, yetkili kurum, kuruluş ve
        mercilerin kararlarını yerine getirme, taleplerini cevaplama,
        amaçlarıyla 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen
        kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.
      </p>
      <br />
      <b>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</b>
      <br />
      <br />
      <p>
        Bu bilgiler grup şirketlerimiz, ana hissedarımız, doğrudan/dolaylı
        yurtiçi/yurtdışı iştiraklerimiz, faaliyetlerimizin gereği anlaşmalı
        olduğumuz kurumlar, iş ortaklarımız, denetim şirketleri, sigorta
        şirketleri, elektronik ileti gönderimi sağlayıcı firmaları, sigorta
        poliçesine ilişkin belgelerin ulaştırılabilmesi için posta ve kargo
        şirketleri, gerekli olduğu durumda ve ölçüde avukatları ile veya yasal
        bir zorunluluk gereği bu verileri talep etmeye yetkili olan kamu kurum
        veya kuruluşları, bunlarla sınırlı olmamak üzere ilgili diğer otoriteler
        ile paylaşabilecektir.
      </p>
      <p>
        Ayrıca, ilgili bilgiler 9 Ocak 2008 tarihli ve 26751 sayılı Resmi
        Gazete’de yayımlanan Suç Gelirlerinin Aklanmasının ve Terörün
        Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik uyarınca
        kimlik doğrulaması gerçekleştirilmesi amacıyla doğrudan Koalay Com
        Sigorta ve Reasürans Brokerliği A.Ş. veya ilgili sigorta şirketi
        tarafından ödeme kuruluşlarıyla paylaşılabilecektir.
      </p>
      <p>
        Cihazınıza yerleştirilen çerezler aracılığıyla elde edilen kişisel
        verileriniz üçüncü kişiler ile işbu Bilgilendirme’de belirtilen kapsam
        ve amaçlarla paylaşılabilecektir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği A.Ş. kişisel verileri
        yukarıda belirtilen kategoriler ve amaçlar dahilinde bu amaçlarla
        sınırlı ve orantılı olmak üzere yurt içinde üçüncü kişilere
        aktarabileceği gibi yurt dışına da aktarabilecektir.
      </p>
      <br />
      <b>Kişisel Verileri Saklama Süresi</b>
      <br />
      <br />
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği, toplanan kişisel verileri,
        ilgili mevzuatta belirtilen veya işlendikleri amaç için gerekli olan
        azami süre ve kanuni zamanaşımı süresince saklayacaktır.
      </p>
      <br />
      <b> Veri Güvenliğine İlişkin Önlemlerimiz ve Taahhütlerimiz</b>
      <br />
      <br />
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği, kişisel verileri güvenli bir
        şekilde korumayı taahhüt eder. Kişisel verilerin hukuka aykırı olarak
        işlenmesini ve erişilmesini engellemek ve kişisel verilerin muhafazasını
        sağlamak amacıyla uygun güvenlik düzeyini temin etmeye yönelik teknik ve
        idari tedbirleri çeşitli yöntemler ve güvenlik teknolojileri kullanarak
        gerçekleştirilmektedir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği, elde ettiği kişisel verileri
        bu işbu Bilgilendirme ve 6698 Sayılı Kişisel Verilerin Korunması Kanunu
        hükümlerine aykırı olarak başkasına açıklamayacaktır ve işleme amacı
        dışında kullanmayacaktır. Koalay Com Sigorta ve Reasürans Brokerliği,
        işbu Bilgilendirme uyarınca dış kaynak hizmet sağlayıcılarla
        Kullanıcılar’a ait kişisel verilerin paylaşılması durumunda, söz konusu
        dış kaynak tedarikçilerinin de işbu madde altında yer alan taahhütlere
        riayet etmeleri için gerekli uyarı ve denetim faaliyetlerini icra
        edeceğini beyan eder.
      </p>
      <p>
        Portal üzerinden başka uygulamalara link verilmesi halinde Koalay Com
        Sigorta ve Reasürans Brokerliği, link verilen uygulamaların gizlilik
        politikaları ve içeriklerine yönelik herhangi bir sorumluluk
        taşımamaktadır.
      </p>
      <br />
      <b>6698 sayılı Kanun’un 11. Maddesi Kapsamında Sayılan Haklarınız</b>
      <br />
      <br />
      <p>
        Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda
        düzenlenen yöntemlerle Koalay Com Sigorta ve Reasürans Brokerliği’ne
        iletmeniz durumunda Koalay Com Sigorta ve Reasürans Brokerliği talebin
        niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde ücretsiz
        olarak sonuçlandıracaktır. Ancak, işlemin Şirket için ayrıca bir
        maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından
        Veri Sorumlusuna Başvuru Usül ve Esasları Hakkında Tebliği’de belirlenen
        tarifedeki ücret alınabilir.
      </p>
      <p>
        Bu kapsamda dilediğiniz zaman Kanunun 11. Maddesi kapsamında Veri
        Sorumlusu olan Şirketimize başvurarak aşağıda belirtilen hakları
        kullanabilirsiniz.
      </p>
      <p>
        Buna göre kişisel verisi işlenen müşterilerimiz;
        <ul style={{ margin: ' 10px 0 10px 30px', listStyle: 'disc' }}>
          <li>Kendisine ait kişisel verilerin işlenip işlenmediğini öğrenme</li>
          <li>
            İşlenen kişisel verileri varsa bunlara ilişkin bilgi talep etme,
          </li>
          <li>
            Kişisel verilerin işlenme amacını ve bu verilerin amaca uygun
            kullanılıp kullanılmadığını öğrenme,
          </li>
          <li>
            Yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı üçüncü
            kişileri bilme, kişisel verilerindeki hataların düzeltilmesini ve
            eğer aktarım yapılmışsa ilgili üçüncü kişiden bu düzeltmenin
            istenmesini talep etme,
          </li>
          <li>
            Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
            düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
            verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
          </li>
          <li>
            Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması
            ve yasal yükümlülük sürelerinin sona ermesi halinde bu verilerin
            silinmesini, yok edilmesini ya da anonim hale getirilmesini isteme
            ve eğer aktarım yapılmışsa bu talebin aktarılan üçüncü kişiye
            iletilmesini isteme,
          </li>
          <li>
            İşlenen verilerin neticesinde kişi ile ilintili olumsuz bir sonuç
            çıkmasına itiraz etme,
          </li>
          <li>
            Kanun’a aykırı veri işleme nedeniyle zararının ortaya çıkması
            halinde zararını yasalar çerçevesinde talep etme,
          </li>
        </ul>
        haklarına sahiptirler.
      </p>
      <p>
        Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, Türkçe
        olarak; veri sorumlusu sıfatıyla Koalay Com Sigorta ve Reasürans
        Brokerliği’nin İçerenköy Mahallesi Değirmenyolu Cad. 37/2 Ataşehir 34752
        İstanbul adresine kimliğinizi tespit edici belgeler ile elden
        iletebilir, noter kanalıyla,{' '}
        <a
          href='mailto:koalay@hs03.kep.tr'
          target='_blank'
          rel='noopener noreferrer'
        >
          koalay@hs03.kep.tr
        </a>{' '}
        adresine kayıtlı elektronik posta olarak veya Kişisel Verileri Koruma
        Kurumu tarafından belirlenen diğer yöntemler ile iletebilirsiniz.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği’nin cevap vermeden önce
        kimliğinizi doğrulama hakkı saklıdır.
      </p>
      <p>
        Başvurunuzda;
        <ul
          style={{
            margin: '10px 0 10px 30px',
            listStyle: 'disc',
          }}
        >
          <li>Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,</li>
          <li>
            Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numaranızın,
            yabancı iseniz uyruğunuzun, pasaport numaranızın veya varsa kimlik
            numaranızın,
          </li>
          <li>Tebligata esas yerleşim yeri veya iş yeri adresinizin,</li>
          <li>
            Varsa bildirime esas elektronik posta adresi, telefon ve faks
            numaranızın,
          </li>
          <li>Talep konunuzun,</li>
        </ul>
        bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de
        başvuruya eklenmesi gerekmektedir.
      </p>
      <br />
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği A.Ş. <br />
        İçerenköy Mahallesi Değirmenyolu Cad. 37/2 Ataşehir 34752 <br />
        T: 0216 570 0876 <br />
        F: 0216 570 0877 <br />
        <a
          href='mailto:koalay@hs03.kep.tr'
          rel='noopener noreferrer'
          target='_blank'
          class='prevent-sbn'
        >
          koalay@hs03.kep.tr
        </a>{' '}
        <br />
      </p>
      <p>
        Kozyatağı V.D. 8370537896 <br />
        Ticaret Sicil: İstanbul 749438 <br />
        Mersis # 0837053789600017 <br />
        Broker # 1166-18740 <br />
        Broker Ruhsat Tarihi 29.11.2013 <br />
      </p>
    </div>
  );
};

export default KvkkInformation;
