// @ts-nocheck
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { forwardRef, useContext, useState } from 'react';
import { DaskHomeContext } from '../context/DaskHomeContext';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Panel } from '../../../../../components/Panel';
import { getLossPayees } from '../../../../../apiServices/generalService';
import { useSnackbar } from 'notistack';
import { PolicyType, UnhandledErrorText } from '../../../constants';
import { grey } from '@material-ui/core/colors';
import { KoalayButton } from './../../../../../components/KoalayButton/KoalayButton';
import { AppContext } from '../../../../../providers/AppProvider';
import { Autocomplete } from '../../../../../components/Autocomplete';
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { triggerGAEvent } from '../../../../../helpers/gaEvent';
import { gaEvents } from '../../../../../data/gaEvents';

const QuoteSummarySection = forwardRef(({ completeSection, isActive }, ref) => {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */

  const { enqueueSnackbar } = useSnackbar();
  const { isMobile, isRenewal } = useContext(AppContext);
  const { daskResultQuote, goPreviousSection } = useContext(DaskHomeContext);
  const {
    getValues,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [parentLossPayeeOptions, setParentLossPayeeOptions] = useState([]);
  const [branchLossPayeeOptions, setBranchLossPayeeOptions] = useState([]);

  const [hasLossPayee, setHasLossPayee] = useState(false);
  const [loading, setLoading] = useState(false);

  const policyType = getValues('policyType');
  const parentLossPayee = watch('parentLossPayee');
  const branchLossPayee = watch('branchLossPayee');
  const productId = daskResultQuote?.productId;
  const teklifUId = daskResultQuote?.quotationUID;
  const isNewPolicy = policyType === PolicyType.NEW;

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  /**
   * Parent Loss Payee cannot be empty when has loss payee
   */
  const validateBeforeOnComplete = () => {
    if (hasLossPayee && !parentLossPayee) {
      return Promise.reject(
        'Lütfen daire üzerinde rehinli alacak olup olmadığını belirtiniz. Eğer var ise alacaklı kurum bilgilerini lütfen belirtiniz.'
      );
    }

    if (
        hasLossPayee &&
        branchLossPayeeOptions?.length > 0 &&
        !branchLossPayee
    ) {
      return Promise.reject('Lütfen şube bilgisini giriniz.');
    }

    return Promise.resolve(true);
  };

	useEffect(() => {
		if(isActive){
		triggerGAEvent(isRenewal ? gaEvents.RenewalOfferInfoSeen : gaEvents.PolicyOfferInfoSeen)
		}
	}, [])
	

  /**
   * Complete quote summary section
   */
  const onComplete = async () => {
    try {
      //* return without model if there is no loss payee
      if (!hasLossPayee) {
				triggerGAEvent(isRenewal ? gaEvents.RenewalDmNo : gaEvents.PolicyDmNo)
        return completeSection();
      }
			triggerGAEvent(isRenewal ? gaEvents.RenewalDmYes : gaEvents.PolicyDmYes)
      await validateBeforeOnComplete();

      //* use branch loss Payee if there is a branch loss Payee, otherwise use parent loss payee
      const id = parseInt(branchLossPayee || parentLossPayee);
      const identityNo = branchLossPayee
        ? branchLossPayeeOptions.find((x) => x.Value === id).IdentityNo
        : parentLossPayeeOptions.find((x) => x.Value === id).IdentityNo;

      const saveLossPayeeModel = {
        teklifUId,
        lossPayeeInfo: {
          id,
          identityNo
        }
      };

      completeSection(saveLossPayeeModel);
    } catch (errMsg) {
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  };

  /**
   * Get Parent Loss payees and fill parent options state
   */
  const fillParentLossPayeeOptions = async () => {
    try {
      setLoading(true);
      const parents = (await getLossPayees(productId)).data.lossPayees;

      const options = parents.map(({ id, name, identityNo }) => {
        return { Value: id, Text: name, IdentityNo: identityNo };
      });
      setParentLossPayeeOptions(options);
    } catch (error) {
      enqueueSnackbar(UnhandledErrorText, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Get Branch loss payees and fill the branch options state
   * @param {*} parent  parent loss payee
   */
  const fillBranchLossPayeeOptions = async (parent) => {
    if (!parent) return;
    try {
      setLoading(true);
      const branches = (await getLossPayees(productId, parent))
        .data.lossPayees;

      const options = branches.map(({ id, name, identityNo }) => {
        return { Value: id, Text: name, IdentityNo: identityNo };
      });
      setBranchLossPayeeOptions(options);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle has loss payee check event
   * Reset loss payee parent / branch options and values
   * if true -> trigger fetching daini place from server
   * @param event -> target.value -> TRUE | FALSE
   */
  const handleHasLossPayeeChange = (event) => {
    const _hasDaini = event.target.value === 'true';

    setHasLossPayee(_hasDaini);
    setValue('parentLossPayee', undefined);
    setValue('branchLossPayee', undefined);

    if (_hasDaini) {
      fillParentLossPayeeOptions();
    } else {
      setParentLossPayeeOptions([]);
      setBranchLossPayeeOptions([]);
    }
  };

  /**
   * Handle Parent loss payee change event
   * Fetch and fill branch loss payee branch options
   * @param {*} parent
   */
  const handleParentLossPayeeChange = (parent) => {
    setValue('branchLossPayee', undefined);
    setBranchLossPayeeOptions([]);

    fillBranchLossPayeeOptions(parent);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <div>
      <Panel ref={ref} style={{ width: '100%' }} goBack={goPreviousSection}>
        <SummaryHeader
          price={daskResultQuote?.grossPremium}
          logoName={daskResultQuote?.insurerElwinName}
          isMobile={isMobile}
        />

        <br />
        <br />
        <Alert style={{ width: '100%' }} severity="info">
          <Typography variant="caption">
            DASK (zorunlu deprem sigortası) için ödenmesi gereken prim tutarı,
            "Zorunlu Deprem Sigortası Tarife ve Talimat Tebliği" ile
            belirlendiğinden, sigorta şirketine göre değişiklik göstermez. Bu
            nedenle yalnızca bir sigorta teklifi göreceksiniz.
          </Typography>
        </Alert>

        <Divider style={{ margin: '20px 0' }} />
        <Box display="flex" flexDirection="column" m={2}>
          <div style={{ marginBottom: 20 }}>
            Poliçenizi oluşturmadan önce lütfen aşağıdaki bilgileri bizimle
            paylaşın. Bu bilgiler prime etki etmeyecektir.
          </div>
          {/* Has Loss Payee radio*/}
          <FormControl component="div">
            <FormLabel component="legend">
              Rehinli alacak var mı? (Dain-i mürtehin)
            </FormLabel>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={hasLossPayee}
              onChange={handleHasLossPayeeChange}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio size="small" disabled={!isActive} color="primary" />
                }
                label="Evet"
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio size="small" disabled={!isActive} color="primary" />
                }
                label="Hayır"
              />
            </RadioGroup>
          </FormControl>

          {/* Parent Loss Payee select */}
          {parentLossPayeeOptions.length > 0 && (
            <>
              <br />
              <Controller
                name="parentLossPayee"
                control={control}
                rules={{
                  required: isNewPolicy ? true : false
                }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    label="Kurum Adı"
                    error={errors.parentLossPayee ? true : false}
                    value={value}
                    onChange={(val) => {
                      handleParentLossPayeeChange(val);
                      onChange(val);
                    }}
                    options={parentLossPayeeOptions}
                    size="small"
                    disabled={!isActive}
                    style={{ width: isMobile ? '100%' : '75%' }}
                  />
                )}
              />
            </>
          )}

          {/*Branch Loss Payee select*/}
          {branchLossPayeeOptions.length > 0 && (
            <>
              <br />
              <Controller
                name="branchLossPayee"
                control={control}
                rules={{
                  required: isNewPolicy ? true : false
                }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    label="Şube Adı"
                    error={errors.branchLossPayee ? true : false}
                    value={value}
                    onChange={onChange}
                    options={branchLossPayeeOptions}
                    size="small"
                    disabled={!isActive}
                    style={{ width: isMobile ? '100%' : '75%' }}
                  />
                )}
              />
            </>
          )}
        </Box>

        <br />

        {/* Submit Button */}
        {isActive && (
          <Grid container alignItems="center">
            {!isMobile && (
              <Grid item xs={12} sm={8} style={{ textAlign: 'left' }}>
                <Button
                  startIcon={<KeyboardArrowUpIcon />}
                  onClick={goPreviousSection}
                >
                  Önceki Adıma Geri Dön
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
              <KoalayButton
                variant="contained"
                onClick={onComplete}
                endIcon={<ChevronRightIcon />}
                startIcon={loading ? <CircularProgress size={24} /> : undefined}
                disabled={loading}
              >
                Poliçenizi Satın Alın
              </KoalayButton>
            </Grid>
          </Grid>
        )}
      </Panel>
    </div>
  );
});

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */

function SummaryHeader({ price, logoName, isMobile }) {
  return (
    <Grid
      container
      spacing={2}
      justify="flex-start"
      alignItems="center"
      style={{ textAlign: 'center' }}
    >
      <Grid item sm={6} xs={12}>
        <Typography variant="h5" style={{ color: grey[500] }}>
          DASK Poliçesi Prim Tutarınız:
        </Typography>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Typography
          variant="h5"
          style={{ fontWeight: 700, fontSize: '1.8rem' }}
          color="primary"
        >
          {price} TL
        </Typography>
      </Grid>
      <Grid item sm={3} xs={12} style={{ order: isMobile && -1 }}>
        <img
          alt="dask-premium-logo"
          style={{
            width: 100,
            float: !isMobile && 'right',
            display: 'inline'
          }}
          src={`https://static.koalay.com/images/koalayapp/dask/insurerlogos/${logoName}.jpg`}
        />
      </Grid>
    </Grid>
  );
}
export default React.memo(QuoteSummarySection);
