export const TKDSGCLHZM = {
  header: {
    left: {
      authorizedAgencyLogo: true,
    },
    right: {
      phoneNumber: "0216 570 07 59",
      partnerLogo: true,
    },
  },
};
