import { createContext } from "react";

export default createContext({
  funnelType: undefined,
  setFunnelType: undefined,
  funnelData: undefined,
  mainLogoHref: undefined,
  setMainLogoHref: undefined,
  isMobile: undefined,
  globalLoading: undefined,
  setGlobalLoading: undefined,
  SSWLConfig: undefined,
  isRenewal: undefined,
  setIsRenewal: undefined,
});
