import React, { useState, useEffect } from 'react';
import { getBuildings } from '../../../../../../../apiServices/generalService';
import { Autocomplete } from '../../../../../../../components/Autocomplete';
import { useSnackbar } from 'notistack';
import { UAVTErrorText } from './../../../../../constants';

const BuildingSelect = ({ street, textValue,isUavt=true, error, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
	const [uavt,setUavt] = useState(isUavt)

  const { enqueueSnackbar } = useSnackbar();
  const { value, onChange } = rest;
  useEffect(() => {
    async function fillOptions() {
      try {
        setLoading(true);
        const response = (await getBuildings(street)).data;
        setOptions(response);
        //current value yeni options içinde yok ise value'u sıfırla
        if (value) {
          const matchingOption = response.find((x) => x.Value === value.Value);
          if (matchingOption) {
            onChange(matchingOption.Value, matchingOption);
          } else {
            onChange && onChange(undefined, undefined);
          }
        }
      } catch (error) {
        enqueueSnackbar(UAVTErrorText, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }

    if (street && uavt) {
      fillOptions();
    } else {
      if (options.length !== 0) onChange && onChange(undefined, undefined)      
      setOptions([]);
    }
		setUavt(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [street]);

  return <Autocomplete options={options} loading={loading} error={error} {...rest} />;
};

export default BuildingSelect;
