import { createContext } from 'react';

const PaymentContext = createContext({
  customer: undefined,
  quote: undefined,
  address: undefined,
  isInit: undefined,
  paymentPlan: undefined,
  setPaymentPlan: undefined
});

export default PaymentContext;
