import { urls } from './url.config';
import { env } from './env.config';

import { TKDSGCLHZM } from './SSWL/TKDSGCLHZM';
import { FTRBZDLK } from './SSWL/FTRBZDLK';
import { DRNSGHTDLK } from './SSWL/DRNSGHTDLK';
import { BDNRJTMCK } from './SSWL/BDNRJTMCK';

const SSWL = {
	TKDSGCLHZM,
	FTRBZDLK,
	DRNSGHTDLK,
	BDNRJTMCK,
};

export const configs = {
	SSWL,
	urls,
	env,
};
