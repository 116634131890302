// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { getCities, getDistricts, getTowns, getVillages } from "../../../../../../apiServices/generalService";

import { KoalayButton } from "../../../../../../components/KoalayButton/KoalayButton";
import Autocomplete from "../../../../../../components/Autocomplete/Autocomplete";
import { EntryType } from "../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../../providers/AppProvider";
import { DaskHomeContext } from "../../context/DaskHomeContext";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";
import delayHelper from "./../../../../../../helpers/delayHelper";
import UAVTAddressSection from "../UAVTAddressSection/UAVTAddressSection";
import { triggerGAEvent } from "../../../../../../helpers/gaEvent";
import { gaEvents } from "../../../../../../data/gaEvents";

const NewAdress = ({ isActive, goNextStep, changeAddressType }) => {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */

  const { isMobile, globalLoading } = useContext(AppContext);
  const { addressOptions, setAddressOptions } = useContext(DaskHomeContext);
  const { enqueueSnackbar } = useSnackbar();
  const { formState, control, watch, trigger, getValues } = useFormContext();
  const { cities, towns, villages, districts } = addressOptions || {};

  const { errors } = formState;
  const { city, village, district, street, building, independentPart, block, plot, lot, page } =
    watch("newAddress") || {};

  const styleProps = { style: { width: isMobile ? "100%" : "50%" } };

  /* -------------------------------------------------------------------------- */
  /*                                   EFFECTS                                  */
  /* -------------------------------------------------------------------------- */

  //fill cities
  useEffect(() => {
    async function fillCities() {
      const _cities = (await getCities()).data;

      setAddressOptions({ ...addressOptions, cities: _cities });
    }
    cities.length === 0 && fillCities();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                 ON CHANGES                                 */
  /* -------------------------------------------------------------------------- */

  /**
   * Handle city change for re-fill town
   * @param {*} _cityCode -> new city value
   * @param {*} onChange -> callBack  from hook-form
   */

  
  async function submit() {
    trigger();
    await delayHelper(500);
    const entryType = localStorage.getItem('entryType');
    if (!isEmpty(errors?.newAddress) && entryType == EntryType.new) {
      showError();
    } else if (!isEmpty(errors?.existAddress) && entryType == EntryType.uavt) {
      showError();
    } else {
      goNextStep();
    }
  }

  const showError = () => {
    enqueueSnackbar("Eksik yada hatalı giriş yaptınız.", {
      variant: "error",
    });
    return;
  }

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <br />
        <>
          <br />
          <UAVTAddressSection prevUavt={localStorage.getItem('uavtCode')}></UAVTAddressSection>
        </>
      <br />

      {/* Action buttons */}
      {isActive && (
        <>
          <Grid container spacing={2} fullWidth>
            {changeAddressType && (
              <Grid item xs={12} sm={7}>
                <KoalayButton
                  style={{
                    backgroundColor: "#fff",
                    color: "inherit",
                  }}
                  variant="contained"
                  onClick={()=> {
										changeAddressType();
										triggerGAEvent(gaEvents.PolicyAddressNewCancel);
									}}
                >
                  Görünen Adrese Dönmek İstiyorum
                </KoalayButton>
              </Grid>
            )}

            <Grid item xs={12} sm={5}>
              <KoalayButton
                disabled={globalLoading}
                startIcon={globalLoading ? <CircularProgress size={24} /> : undefined}
                fullWidth
                variant="contained"
                endIcon={<ChevronRightIcon />}
                onClick={()=> {
									submit();
									triggerGAEvent(gaEvents.PolicyAddressNewAddress)
								}}
              >
                Bu Adresle Devam Et
              </KoalayButton>
            </Grid>
          </Grid>
          <br />
          <Alert severity="info">
            <Typography variant="caption">
              Cadde adı, sokak adı ya da apartman ve daire numarası gibi detaylı bilgiler sonraki ekranlarda
              sorulacaktır.{" "}
            </Typography>
          </Alert>
        </>
      )}
    </>
  );
};

export default NewAdress;
