import Axios from "axios";
import axiosInstance from "../helpers/axiosHelper";
// import { MockAddressFromUAVT } from './mockData';
// import { MockProductParamaters, MockBankDainiPlace, MockInsDainiPlace } from './mockData';

export async function getCities() {
  return await axiosInstance.get(`General/GetCities`);

  // return MockCities
}

export async function getLossPayees(productId, parentId) {
  //TODO eski kodda service.js içinde cache=true set edilmiş.
  return await axiosInstance.get(`General/GetLossPayee?productId=${productId}&parentId=${parentId}`);

  // return typeId === 1 ? { data: MockBankDainiPlace } : { data: MockInsDainiPlace };
}

export async function getDistricts(villageCode) {
  return await axiosInstance.get(`General/GetDistricts?villageCode=${villageCode}`);
  // return MockDistricts
}

export async function getProductParameters(productGroupId) {
  return await axiosInstance.get(`General/GetProductParameters?productGroupId=${productGroupId}`);
  // return MockProductParamaters;
}

export async function getTowns(cityCode) {
  return await axiosInstance.get(`General/GetTowns?cityCode=${cityCode}`);

  // return MockTowns
}

export async function getVillages(townCode) {
  return await axiosInstance.get(`General/GetVillages?townCode=${townCode}`);

  // return MockVillages;
}

export async function getAddressByUavtNo(uavtCode) {
  return await axiosInstance.get("General/GetAddressByUavtNo?uavtNo=" + uavtCode);
  // return MockAddressFromUAVT;
}

export async function getDaskPolicyDetail(tckn, uavt,userGuid, callback) {
  const consumer = JSON.parse(localStorage.getItem("consumer"));
	consumer.queryParams.all.jt = userGuid;
  Axios.defaults.headers.common["X-Consumer"] = JSON.stringify(consumer);
  const url =
    document.location.href.indexOf("testself") >= 0 || document.location.href.indexOf("local") >= 0
      ? `http://192.168.180.200/api/quotation/get-dask-policy-detail`
      : document.location.href.indexOf("qa.") >= 0
      ? `${document.location.protocol}//apiqa.fincase.tech/api/quotation/get-dask-policy-detail`
      : `${document.location.protocol}//api.fincase.tech/api/quotation/get-dask-policy-detail`;
  await Axios.get(url + `?IdentityNumber=${tckn}&AddressNo=${uavt}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

export async function getUserAgreementHtml({ sbnCode, extRefId }) {
  return await axiosInstance.get(`General/GetUserAgreementHtml?sbnCode=" + ${sbnCode} + "&extRefId=" + ${extRefId}`);
}

export async function getStreets(districtCode) {
  return await axiosInstance.get(`General/GetStreets?districtCode=${districtCode}`);
}
export async function getBuildings(streestCode) {
  return await axiosInstance.get(`General/GetBuildings?streestCode=${streestCode}`);
}
export async function getIndependentParts(buildingCode) {
  return await axiosInstance.get(`General/GetIndependentParts?buildingCode=${buildingCode}`);
}
