import React, { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { AppContext } from '../../../../providers/AppProvider';

const CustomIframe = ({
  children,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null)
  const { isMobile } = useContext(AppContext);
  const mountNode =
    contentRef?.contentWindow?.document?.body

  return (
    <iframe {...props} ref={setContentRef} style={{
			width: isMobile ? '100%' : '90%',
			height: isMobile ? '100%': '80%',
			left: '50%',
			top: '50%',
			position: 'relative',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#fff',
			border: '1px solid #999',
			borderRadius: '6px',
			outline: '0',
			boxShadow: '0 3px 9px rgb(0 0 0 / 50%)',
		}}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
}

export default CustomIframe;