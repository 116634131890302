import Axios from "axios";
import axiosInstance from "../helpers/axiosHelper";
import axiosDotNetInstance from "../helpers/axiosDotNETHelper";
// import { MockPostCustomerInfoResponse } from './mockData';
// import { MockPostCustomerInfoResponse, MockQuotationDetail, MockQuote } from './mockData';

export async function getQuatationDetail(data) {
  return await axiosInstance({
    url: `SideProducts/GetQuotationDetail`,
    method: "post",
    // credentials: 'same-origin',
    data,
  });
  // return MockQuotationDetail;
}

export async function getQuotes(quoteModel) {
  const consumer = JSON.parse(localStorage.getItem("consumer"));
  axiosDotNetInstance.defaults.headers.common["X-Consumer"] = JSON.stringify(consumer);
  return await axiosDotNetInstance({
    url: `/quotation/side-products/quotes`,
    method: "post",
    data: quoteModel,
  });

  // return MockQuote;
}

export async function postCustomerInfo(tckn, oldPolicyNo, data) {
  let url = `SideProducts/postCustomerInfo?tckNo=${tckn}`;

  if (oldPolicyNo) {
    url = `${url}&oldPolicyNo=${oldPolicyNo}`;
  }
  return await axiosInstance({
    url,
    method: "post",
    data,
  });
  // return MockPostCustomerInfoResponse;
}

export async function purchase(data) {
  return await axiosInstance({
    url: `SideProducts/issue`,
    method: "post",
    data,
  });
}
export async function purchaseWAB(data,userGuid,callback) {
	const consumer = JSON.parse(localStorage.getItem("consumer"));
	consumer.queryParams.all.jt = userGuid;
	Axios.defaults.headers.common['X-Consumer'] = JSON.stringify(consumer);
	const url = document.location.href.indexOf("testself") >= 0 || document.location.href.indexOf("localhost") >= 0 ? `https://apitest.fincase.tech/api/payment/session-token` : `${document.location.protocol}//api.fincase.tech/api/payment/session-token`
	await Axios.post(url,{...data}).then((response) => {
    try {
      callback(response);
    } catch (error) {
      console.log(error);
    }
  });
}

export async function postDaskRenewalQuote(qGuid) {
  return await axiosInstance({
    method: "POST",
    url: "SideProducts/PostDaskRenewalQuote?qGuid=" + qGuid + "",
  });
}

export async function updateUserAddress(addressUpdateModel) {
  return await axiosInstance({
    method: "POST",
    url: "SideProducts/UpdateUserAddress",
    data: addressUpdateModel,
  });
}

export async function saveLossPayee(saveLossPayeeeModel) {
  return await axiosInstance({
    method: "POST",
    url: "SideProducts/SaveLossPayeeInfo",
    data: saveLossPayeeeModel,
  });
}

export async function sendMail(model) {
  return await axiosInstance({
    method: "POST",
    url: "SideProducts/SendMail",
    data: model,
  });
}
