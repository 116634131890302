import React, { useContext, useEffect, useState } from "react";
import { Box, Container } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import { AppContext } from "../../providers/AppProvider";
import { palette } from "./../../helpers/palette";
import { configs } from "../../configs";
import { shouldMergeClassNames } from "../../helpers/styling.helper";
import { useStyles } from "./Header.style";

/**
 * App Header Component
 * TODO css yapısını styled componente çekerken biraz toplamak lazım. Minimum css kullanalım
 */

const Header = () => {
  const [headerProperties, setHeaderProperties] = useState({
    desktopLogoSrc: configs.urls.images.defaultDesktopHeaderLogo,
    mobileLogoSrc: configs.urls.images.defaultMobileHeaderLogo,
    logo: configs.urls.images.DASKLogo,
    phoneNumber: null,
  });
  const { mainLogoHref, isMobile, SSWLConfig } = useContext(AppContext);

	const URL_PARAMS = new URLSearchParams(document.location.search);
	const extRefHash = URL_PARAMS.get('extRefHash');
	const partnerSbn = SSWLConfig.partnerSbn;
	let logoHref = mainLogoHref
	if(partnerSbn === 'BDNRJTMCK' && extRefHash){
		logoHref = `/dask?extRefHash=${extRefHash}`
	}
  const mainLogoBoxProps = isMobile
    ? { textAlign: "center", flexGrow: 1 }
    : undefined;

  const classes = useStyles();

  useEffect(() => {
    const headerConfig = SSWLConfig.header;
    const partnerSbn = SSWLConfig.partnerSbn;
    if (headerConfig) {
      const {
        left: { authorizedAgencyLogo },
        right: { phoneNumber, partnerLogo },
      } = headerConfig;

      setHeaderProperties({
        desktopLogoSrc: authorizedAgencyLogo
          ? configs.urls.images.agencyDesktopHeaderLogo
          : headerProperties.desktopLogoSrc,
        mobileLogoSrc: authorizedAgencyLogo
          ? configs.urls.images.agencyMobileHeaderLogo
          : headerProperties.mobileLogoSrc,
        phoneNumber: phoneNumber ?? headerProperties.phoneNumber,
        logo: partnerLogo
          ? configs.urls.partnerLogo(partnerSbn)
          : headerProperties.logo,
      });
    }
  }, []);

  return (
    <Box
      style={{
        minHeight: isMobile ? 60 : 80,
        backgroundColor: isMobile ? palette.koalayGreenColor : "#fff",
        minWidth: "100%",
      }}
    >
      <Container
        style={{
          maxWidth: isMobile ? "100%" : 768,
          minHeight: isMobile ? 60 : 80,
          display: "flex",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "center",
        }}
      >
        <Box {...mainLogoBoxProps}>
          <a href={logoHref}>
            <img
              alt="Koalay Logo"
              src={
                isMobile
                  ? headerProperties.mobileLogoSrc
                  : headerProperties.desktopLogoSrc
              }
              width="180"
              style={{ display: "inline" }}
            />
          </a>
        </Box>

        <Box
          className={shouldMergeClassNames(
            isMobile,
            1,
            classes.rightHeaderContainer,
            classes.hide
          )}
        >
          <img
            className={classes.headerPartnerLogo}
            alt="Dask Logo"
            src={headerProperties.logo}
          />
          <Box hidden={isMobile || !headerProperties.phoneNumber}>
            <div>
              <a
                className={classes.headerPhoneNumber}
                href={`tel:${headerProperties.phoneNumber}`}
              >
                <PhoneIcon /> {headerProperties.phoneNumber}
              </a>
            </div>
          </Box>
        </Box>

        <Box hidden={!isMobile}>
          <a href={`tel:${headerProperties.phoneNumber ?? "02165700800"}`}>
            <PhoneIcon
              style={{
                backgroundColor: "#fff",
                color: palette.koalayGreenColor,
                borderRadius: 5,
              }}
            />
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
