import { createContext } from 'react';

export const DaskHomeContext = createContext({
  policyType: undefined,
  setPolicyType: undefined,
  customer: undefined,
  existAddress: undefined,
  newAddress: undefined,
  funnelData: undefined,
  productParameters: undefined,
  daskResultQuote: undefined,
  loading: undefined,
  setLoading: undefined,
  goPreviousSection: undefined,
  isForcePrepare: undefined,
  hasTaxNumber: undefined,
  addressOptions: undefined,
  setAddressOptions: undefined
});
