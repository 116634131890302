// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { Button, Divider, Typography, Link, Hidden } from '@material-ui/core';
import { Panel } from '../../../../components/Panel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  getQuatationDetail,
  sendMail
} from '../../../../apiServices/sideProductService';
import { Box } from '@material-ui/core';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import EcoIcon from '@material-ui/icons/Eco';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { AppContext } from '../../../../providers/AppProvider';
import { TextField } from '@material-ui/core';
import { EmailOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { validateEmail } from './../../../../helpers/utils';
import { UnhandledErrorText } from '../../constants';

// import PaymentContext from '../Payment/context/PaymentContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KoalayButton } from '../../../../components/KoalayButton';
import { triggerGAEvent } from '../../../../helpers/gaEvent';
import { gaEvents } from '../../../../data/gaEvents';

const InvalidEmailWarningMessage = 'Lütfen geçerli bir mail adresi giriniz.';

/**
 * Dask Thankyou page
 *
 */
const ThankYouPage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { isMobile, setMainLogoHref, isRenewal } = useContext(AppContext);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [state, setState] = useState({ isInit: false });

  const {
    customer,
    customerEmail,
    policyNumber,
    teklifId,
    isInit,
    qGuid
  } = state;

  useEffect(() => {
    async function init(teklifTId) {
      try {
        const res = (await getQuatationDetail({ teklifTId })).data.result;
        if (!res) {
          enqueueSnackbar(UnhandledErrorText, { variant: 'error' });
          return;
        }
        setState({
          customer: res.customer,
          customerEmail: res.policy.eMail,
          policyNumber: res.insurerPolicyNo,
          teklifId: res.teklifTId,
          qGuid: res.quotations[0].quotationUID,
          isInit: true
        });
      } catch (error) {
        enqueueSnackbar(UnhandledErrorText, { variant: 'error' });
      }
    }
    const teklifTId = queryString.parse(search)?.teklifTId;
    teklifTId && init(teklifTId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setMainLogoHref('/#');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

	useEffect(() => {
		console.log(isRenewal,isRenewal ? gaEvents.RenewalThankyouSeen : gaEvents.PolicyThankyouSeen)
		triggerGAEvent(isRenewal ? gaEvents.RenewalThankyouSeen : gaEvents.PolicyThankyouSeen)
	}, [])
	

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  const showPolicy = () => {
    //TODO prod url ??
    const showPolicURL = `${document.location.origin}/Policy/GetPolicePdfV2?teklifTId=${teklifId}&qGuid=${qGuid}`;
    window.open(showPolicURL, '_blank');
  };

  const showPaymentReceipt = () => {
    //TODO prod url ??
    const showReceiptURL = `${document.location.origin}/Policy/GetReceiptDocumentV2?teklifTId=${teklifId}&qGuid=${qGuid}`;
    window.open(
      showReceiptURL,
      'socialPopupWindow',
      'location=no,width=800,height=800,scrollbars=yes,top=100,left=700,resizable = no'
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                            HEADER SUB COMPONENTS                           */
  /* -------------------------------------------------------------------------- */

  function MobileHeader() {
    return (
      <Typography variant="h5" style={{ fontWeight: 'bold' }}>
        Tebrikler!
      </Typography>
    );
  }

  function DesktopHeader() {
    return (
      <>
        <Typography variant="h3" style={{ fontWeight: 'bold' }} color="primary">
          <CheckCircleIcon color="primary" fontSize="inherit" />
          <br />
          Poliçeniz hazır!
        </Typography>
        <br />
        <br />
      </>
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return isInit ? (
    <Panel style={{ textAlign: 'center' }}>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
			<Typography variant="h6" style={{display: customer?.name == null || customer?.lastName == null ? "none": 'block'}}>
				Sayın <b>{customer?.name + ' ' + customer?.lastName}</b>,
      </Typography>

      <br />

      <Box style={{ padding: '0 1vw' }}>
        Zorunlu deprem sigortanız{' '}
        <Typography variant="h5" display="inline" style={{ fontWeight: 700 }}>
          "{policyNumber}"
        </Typography>{' '}
        poliçe numarası ile başarıyla oluşturulmuş ve belirttiğiniz e-posta
        adresine gönderilmiştir.
        <br />
        <br />
        Dilerseniz aşağıdaki butona tıklayarak poliçenizin PDF'ini
        görüntüleyebilir veya indirebilirsiniz.
        <br />
        <br />
        <Box
          textAlign="center"
          justifyContent="center"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <KoalayButton
            style={{ marginBottom: isMobile && 15 }}
            variant="contained"
            onClick={showPolicy}
          >
            <PdfIcon /> &nbsp; Poliçe görüntüle
          </KoalayButton>
          <Hidden xsDown>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: '0 .5rem' }}
            />
          </Hidden>
          <KoalayButton
            variant="contained"
            onClick={showPaymentReceipt}
          >
            <PdfIcon />
            &nbsp; Tahsilat Makbuzu
          </KoalayButton>
        </Box>
        <br />
        <EcoIcon />
        <br />
        <br />
        <Typography>
          Poliçeniz e-posta adresinize 2-3 dakika içerisinde gelmezse
          <Link href="#" onClick={() => setShowEmailInput(!showEmailInput)}>
            {' buraya '}
          </Link>
          tıklayarak tekrar gönderebilirsiniz.
        </Typography>
        {showEmailInput && (
          <EmailInput
            customerEmail={customerEmail}
            postModel={{
              customerInfo: customer,
              policyInfo: { TeklifTId: teklifId }
            }}
          />
        )}
      </Box>

      <Divider style={{ margin: '1rem 0' }} />
      <Typography>
        Koalay.com'u tercih ettiğiniz için teşekkür ederiz.
      </Typography>
    </Panel>
  ) : (
    <React.Fragment />
  );
};

/* -------------------------------------------------------------------------- */
/*                            EMAİL INPUT COMPONENT                           */
/* -------------------------------------------------------------------------- */

function EmailInput({ customerEmail, postModel }) {
  const { isMobile } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [mailAddress, setMailAddress] = useState(customerEmail);
  const [error, setError] = useState(() =>
    customerEmail ? !validateEmail(customerEmail) : false
  );
  const { enqueueSnackbar } = useSnackbar();

  function handleMailAddressChange(e) {
    const value = e.target.value;

    setError(validateEmail(value) ? false : true);
    setMailAddress(value);
  }

  const reSendEmail = async () => {
    if (error || !mailAddress) {
      enqueueSnackbar(InvalidEmailWarningMessage, { variant: 'error' });
      return;
    }
    try {
      setLoading(true);
      await sendMail({
        ...postModel,
        customerInfo: { ...postModel.customerEmail, eMail: mailAddress }
      });
      enqueueSnackbar(
        'Poliçeniz e-posta adresinize başarılı bir şekilde gönderilmiştir.',
        { variant: 'success' }
      );
    } catch (error) {
      enqueueSnackbar(UnhandledErrorText, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      style={{ margin: '1rem 0 2rem 0', height: 40 }}
      display="flex"
      justifyContent="center"
    >
      <TextField
        value={mailAddress}
        onChange={handleMailAddressChange}
        size="small"
        error={error}
        variant="outlined"
        label="Email Adresiniz"
        helperText={error && InvalidEmailWarningMessage}
      />
      <Divider orientation="vertical" flexItem style={{ margin: '0 .5rem' }} />
      {isMobile ? (
        <IconButton
          onClick={reSendEmail}
          color="primary"
          style={{ padding: 0 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
      ) : (
        <Button
          onClick={reSendEmail}
          color="primary"
          disabled={loading}
          variant="contained"
          startIcon={
            loading ? <CircularProgress size={24} /> : <EmailOutlined />
          }
        >
          Gönder
        </Button>
      )}
    </Box>
  );
}

export default ThankYouPage;
