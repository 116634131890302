const cdnBaseUrl = "https://static.koalay.com";

const images = {
  defaultDesktopHeaderLogo:
    cdnBaseUrl + "/Images/KoalayApp/Desktop/Quotation/koalay-logo-new.png",
  defaultMobileHeaderLogo:
    cdnBaseUrl + "/Images/KoalayApp/Mobile/Quotation/logo-main.png",
  agencyDesktopHeaderLogo:
    cdnBaseUrl + "/Images/KoalayApp/Desktop/Quotation/agency_header_logo.png",
  agencyMobileHeaderLogo:
    cdnBaseUrl + "/Images/KoalayApp/Mobile/Quotation/agency_header_logo.png",
  DASKLogo: cdnBaseUrl + "/Images/KoalayApp/Dask/DASK_shbndn_logo.png",
};

const partnerLogo = (partnerSbn) =>
	`${cdnBaseUrl}/Images/KoalayApp/Dask/WLLogos/${partnerSbn}.png`;

export const urls = {
	images,
	partnerLogo,
};
