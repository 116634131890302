/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';


export const Unauthorized = () => {
 
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <>
    <img src = "https://static.koalay.com/Images/KoalayApp/Desktop/Marketing/koalay-500-image.png"></img>
    <h1>Bu sayfaya erişim yetkiniz bulunmaktadır.</h1>
    </>
  );
};

export default Unauthorized;