/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, {useEffect, useState } from 'react';

export const RedirectScreen = () => {
 
	const logo = JSON.parse(localStorage.getItem("consumer")).partnerSbn;


  return (
    <>
			<div class="dealer-information-notification">
    <div class="inner">
        <img class="referrer-logo" src={`https://static.koalay.com/Images/KoalayApp/Dask/WLLogos/${logo}.png`}/>
        <div class="mobile-rotate"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div> </div>
        <img class="koalay-logo" src="https://static.koalay.com/Images/KoalayApp/Desktop/Marketing/koalay-logo-new.png" alt="Koalay.com" />
        <br />
        <br />
        <b class="redirection-text">Bir sigorta aracısı olan Koalay Com Sigorta A.Ş.'nin web sitesine yönlendiriliyorsunuz</b>
    </div>
</div>

    </>
  );
};

export default RedirectScreen;
