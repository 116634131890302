// @ts-nocheck
import React, { useState, useEffect, forwardRef } from 'react';
import KoalaySelect from '../SelectFormControl/KoalaySelect';

const YearSelect = forwardRef((props, ref) => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const _years = [...Array(20).keys()].map(i => {
      return { Value: currentYear + i, Text: currentYear + i + '' };
    });
    setYears(_years);
  }, []);

  return <KoalaySelect {...props} ref={ref} data={years} style={{ width: '100%' }} />;
});

export default YearSelect;
