import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  headerPhoneNumber: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    marginLeft: "4px",
    textDecoration: "none",
    fontSize: "1.6em",
    fontWeight: "bold",
    "& svg": {
      marginRight: "4px",
    },
  },

  headerPartnerLogo: {
    height: "100%",
    maxWidth: "180px",
    maxHeight: "35px",
  },

  rightHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  hide: {
    display: "none",
  },
}));
