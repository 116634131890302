// @ts-nocheck
import React, { useState, useEffect, forwardRef } from 'react';
import KoalaySelect from '../SelectFormControl/KoalaySelect';

const MonthSelect = forwardRef((props, ref) => {
  const [months, setMonths] = useState([]);

  useEffect(() => {
    const _months = [...Array(12).keys()].map(i => {
      return { Value: i + 1, Text: i + 1 + '' };
    });
    setMonths(_months);
  }, []);

  return <KoalaySelect {...props} ref={ref} data={months} style={{ width: '100%' }} />;
});

export default MonthSelect;
