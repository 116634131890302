import React, { useContext } from 'react';
import { AppContext } from '../../../../../../providers/AppProvider';
import { KoalayButton } from '../../../../../../components/KoalayButton/KoalayButton';
import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { palette } from '../../../../../../helpers/palette';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PolicyType } from './../../../../constants';
import { Button } from '@material-ui/core';

import { useFormContext } from 'react-hook-form';
import { triggerGAEvent } from '../../../../../../helpers/gaEvent';
import { gaEvents } from '../../../../../../data/gaEvents';

export function ExistAddress({ goNextStep, isActive, changeAddressType }) {
  const { isMobile, isRenewal } = useContext(AppContext);
  const { getValues } = useFormContext();
  const existAddress = getValues('existAddress');
  const policyType = getValues('policyType');

  return (
    <>
      <Box border={!isMobile && `1px solid grey.100`}>
        <Box
          border={`1px dashed ${palette.primaryColor}`}
          p="1rem"
          marginBottom="1rem"
          borderColor="primary"
          borderRadius="borderRadius"
          width={isMobile ? '100%' : 2 / 4}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: 600, fontSize: 18, marginBottom: 10 }}
          >
            Görünen Adres
          </Typography>
          <div>
            {existAddress?.district?.text} {existAddress?.street?.text}{' '}
          </div>
          <div>
            No: {existAddress?.outerDoorNum} Daire: {existAddress?.innerDoorNum}{' '}
          </div>
          <div>
            Ada: {existAddress?.block} / Pafta: {existAddress?.plot} / Parsel:{' '}
            {existAddress?.lot}{' '}
          </div>
          <div
            style={{
              paddingTop: 2,
              paddingBottom: 6,
              fontWeight: 700
            }}
          >
            {existAddress?.town?.text} {existAddress?.city?.text}{' '}
          </div>
        </Box>
        {policyType !== PolicyType.RENEWAL && (
          <>
            <Alert severity="info">
              <Typography variant="caption">
                DASK yapılacak adres yukarıdaki adresten farklıysa, lütfen
                aşağıdaki "Başka adres seçmek istiyorum" seçeneğinden
                ilerleyiniz.
              </Typography>
            </Alert>
            <br />
          </>
        )}

        {isActive && (
          <Grid container spacing={2} fullWidth justify="flex-end">
            {/* //!Cannot change address if renewal policy */}
            {policyType !== PolicyType.RENEWAL && (
              <Grid item xs={12} sm={6}>
								<Button 
								variant="contained" 
								onClick={()=> {
									changeAddressType(); 
									triggerGAEvent(gaEvents.PolicyAddressNew)
								}}       
								style={{
									backgroundColor: '#fff',
									color: 'inherit',
									fontWeight: 600,
									padding: 10,
								}}>		
                  Başka Adres Seçmek İstiyorum
                </Button>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <KoalayButton
                onClick={()=>{
									goNextStep();
									!isRenewal && triggerGAEvent(gaEvents.PolicyAddressContinue)
								}}
                variant="contained"
                fullWidth
                endIcon={<ChevronRightIcon />}
              >
                Bu Adresle Devam Et
              </KoalayButton>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

