// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import React, { forwardRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 300
  }
}));

const KoalaySelect = forwardRef(
  ({ onChange, value, data = [], placeHolder, ...rest }, ref) => {
    const classes = useStyles();

    const handleChange = (event) => {
      onChange && onChange(event.target.value);
    };

    useEffect(() => {
      value && onChange && onChange('');
    }, [data]);

    return (
      <MuiSelect
        variant="outlined"
        value={value}
        inputRef={ref}
        size="small"
        fullWidth
        clear
        onChange={handleChange}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        {...rest}
      >
        {data.map(({ Value: optValue, Text: optText }) => (
          <MenuItem key={optValue} value={optValue}>
            {optText}
          </MenuItem>
        ))}
      </MuiSelect>
    );
  }
);

export default KoalaySelect;
