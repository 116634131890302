// @ts-nocheck
import React, { forwardRef, useContext } from 'react';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { DaskHomeContext } from '../context/DaskHomeContext';
import { Panel } from '../../../../../components/Panel';

import { Controller } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { KoalayButton } from './../../../../../components/KoalayButton/KoalayButton';
import { Autocomplete } from '../../../../../components/Autocomplete';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AppContext } from '../../../../../providers/AppProvider';
import { PolicyType } from './../../../constants';
import { useSnackbar } from 'notistack';
import { MeskenUsageTypeId } from './../../../constants';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import delayHelper from './../../../../../helpers/delayHelper';
import { useEffect } from 'react';
import { getFormFirstError } from './../../../../../helpers/getFormFirstError';
import { triggerGAEvent } from '../../../../../helpers/gaEvent';
import { gaEvents } from '../../../../../data/gaEvents';

const FlatInfoEntrySection = forwardRef(
  ({ completeSection, isActive }, ref) => {
    const { productParameters, loading, goPreviousSection } = useContext(
      DaskHomeContext
    );
    const { isMobile,isRenewal } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const {
      getValues,
      control,
      trigger,
      setValue,
      clearErrors,
      formState: { errors }
    } = useFormContext();

    const {
      buildingStories,
      constructionTypes,
      constructionYears,
      usageTypes
    } = productParameters || {};

    const policyType = getValues('policyType');
    const defaultbuilding = getValues('defaultBuilding') || {};

    const readonly = !isActive || policyType === PolicyType.RENEWAL;

    //TODO: default building set etmek için bir work-around. burası düzeltilecek
    // autocomplete tam olarak render olmadan set yapamadığı için 1 sn delay ekledim
    useEffect(() => {
			if(isActive){
				triggerGAEvent(isRenewal ? gaEvents.RenewalApartmentInfoSeen : gaEvents.PolicyApartmentInfoSeen)
			}
      clearErrors();
      setTimeout(() => {
        if (defaultbuilding) {
          setValue('building', defaultbuilding);
        }
      }, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goNextStep = async () => {
      trigger();
      await delayHelper(100);
      if (errors?.building?.floorArea) {
        enqueueSnackbar(getFormFirstError(errors?.building?.floorArea), {
          variant: 'error'
        });
        return;
      } else if (!isEmpty(errors?.building)) {
        enqueueSnackbar('Eksik veya hatalı giriş yaptınız.', {
          variant: 'error'
        });
        return;
      }

      completeSection();
    };

    const resetFlatInfo = () => {
      setValue('building', {
        constructionType: '',
        usageType: '',
        constructionYear: '',
        floorArea: '',
        buildingStory: ''
      });
      goPreviousSection();
    }

    function handleUsageType(usageType) {
      if (policyType === PolicyType.NEW && usageType !== MeskenUsageTypeId) {
        enqueueSnackbar(
          'Mesken harici kullanım tipindeki dairenizin DASK poliçesinin geçerli olması için, binada mesken kullanımı olması gerekmektedir. İşleminize devam etmeden önce bu bilgiyi doğrulamanızı öneririz.',
          { variant: 'info' }
        );
      }
    }

    return (
      <div>
        <Panel
          loading={loading}
          ref={ref}
          title="Daire Bilgileri"
          icon={faBuilding}
          goBack={goPreviousSection}
        >
          <Alert severity="info">
            <Typography variant="caption">
              Poliçe priminizi doğru hesaplayabilmemiz için lütfen aşağıdaki
              alanları eksiksiz ve doğru bilgilerle doldurun.
            </Typography>
          </Alert>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="building.constructionType"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    error={errors?.building?.constructionType ? true : false}
                    options={constructionTypes}
                    searchable={false}
                    defaultValue={defaultbuilding.constructionType}
                    disabled={readonly}
                    label="Binanın Yapı Tarzı"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="building.usageType"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    error={errors?.building?.usageType ? true : false}
                    size="small"
                    value={value}
                    defaultValue={defaultbuilding.usageType}
                    searchable={false}
                    disabled={readonly}
                    onChange={(val) => {
                      onChange(val);
                      handleUsageType(val);
                    }}
                    label="Dairenin Kullanım Şekli"
                    options={usageTypes}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="building.constructionYear"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    error={errors?.building?.constructionYear ? true : false}
                    size="small"
                    value={value}
                    searchable={false}
                    defaultValue={defaultbuilding.constructionYear}
                    disabled={readonly}
                    onChange={onChange}
                    label="Binanın İnşa Edildiği Yıl"
                    options={constructionYears}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="building.floorArea"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Eksik veya hatalı giriş yaptınız.'
                  },
                  min: {
                    value: 40,
                    message: (
										<span>Dairenin yüz ölçümü minimum 40 m<sup>2</sup> olmalıdır.</span>
										)
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={readonly}
                    fullWidth
                    size="small"
                    variant="outlined"
                    defaultValue={defaultbuilding.floorArea}
                    error={errors?.building?.floorArea}
                    inputProps={{ maxLength: 9999 }}
                    placeholder="Brüt m2"
                    label="Dairenin Brüt Yüz Ölçümü"
                    onInput={(e) => {
                      //TODO: maxlength ayarlamak için workaround. buraya daha temiz bir yöntem bulalım
                      if (e.target.value) {
                        const val = parseInt(e.target.value);
                        e.target.value = isNaN(val)
                          ? ''
                          : Math.max(0, val).toString().slice(0, 4);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="building.buildingStory"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    error={errors?.building?.buildingStory ? true : false}
                    disabled={readonly}
                    defaultValue={defaultbuilding.buildingStory}
                    size="small"
                    searchable={false}
                    label="Binadaki Toplam Kat Sayısı"
                    options={buildingStories}
                  />
                )}
              />
            </Grid>
						<>
							<Alert severity="info">
								<Typography variant="caption">
									Poliçedeki yüz ölçümünün gerçekten farklı olması durumunda sigortalı mağduriyeti yaşanabilir.
								</Typography>
							</Alert>
							<br />
						</>

            {isActive && (
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Grid container alignItems="center">
                  {!isMobile && (
                    <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                      <Button
                        startIcon={<KeyboardArrowUpIcon />}
                        onClick={resetFlatInfo}
                      >
                        Önceki Adıma Geri Dön
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                    <KoalayButton
                      variant="contained"
                      color="primary"
                      endIcon={<ChevronRightIcon />}
                      onClick={goNextStep}
                    >
                      Dask Priminizi Öğrenin
                    </KoalayButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Panel>
      </div>
    );
  }
);

export default React.memo(FlatInfoEntrySection);
