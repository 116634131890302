/** merges class names */
export const mergeClassNames = (...classNames) => classNames.join(" ");

/** merges class names with condition */
export const shouldMergeClassNames = (
  shouldMerge,
  defaultMergeNumber,
  ...classNames
) =>
  shouldMerge
    ? classNames.join(" ")
    : classNames
        .filter((name, index) => index <= defaultMergeNumber - 1)
        .join(" ");
