import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { getUserAgreementHtml } from '../../apiServices/generalService';
import { UnhandledErrorText } from '../../pages/Dask/constants';
import { useSnackbar } from 'notistack';

const UserAgreement = ({ sbnCode, extRefId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [html, setHtml] = useState(undefined);

  useEffect(() => {
    async function getUserAgreement() {
      try {
        var res = await getUserAgreementHtml({ sbnCode, extRefId });

        setHtml(res.data.html);
      } catch (error) {
        enqueueSnackbar(UnhandledErrorText, { variant: 'error' });
      }
    }
    getUserAgreement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sbnCode, extRefId]);

  if (!html) {
    return Array.from({ length: 30 }).map((x) => <Skeleton variant="text" />);
  } else {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
};

export default UserAgreement;
