import { Button, TextField, Typography, Box, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import InfoIcon from "@material-ui/icons/Info";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";
import { getAddressByUavtNo, getBuildings } from "../../../../../../../apiServices/generalService";
import { KoalayDialog } from "./../../../../../../../components/KoalayDialog/KoalayDialog";
import Backdrop from "./../../../../../../../components/Backdrop/Backdrop";
import { useFormContext, Controller } from "react-hook-form";
import { triggerGAEvent } from "../../../../../../../helpers/gaEvent";
import { gaEvents } from "../../../../../../../data/gaEvents";

const UAVTFind = ({ handleUavtAddress, prevUavt }) => {
  const { formState, control, clearErrors, getValues } = useFormContext();
  const { errors } = formState;
  const [uavtCode, setUavtCode] = useState(prevUavt == "undefined" ? undefined : prevUavt);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const findAddress = async () => {
    try {
      setLoading(true);

      const addressFromUavt = (await getAddressByUavtNo(uavtCode)).data;
      const buildingOptions = (await getBuildings(addressFromUavt.Street.Value)).data;
      const Building = buildingOptions.find((x) => x.Text === addressFromUavt.OuterDoorNum);

      localStorage.setItem("uavtCode", uavtCode);
      clearErrors();

      handleUavtAddress({ ...addressFromUavt, Building });

    } catch (error) {
      enqueueSnackbar("Kayıt bulunamadı. Lütfen UAVT kodunuzu kontrol ederek tekrar deneyiniz.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const stateUavt = getValues('existAddress.uavt');
    if (stateUavt) {
      setUavtCode(stateUavt)
    }
  }, []);

  return (
    <Backdrop loading={loading}>
      <KoalayDialog
        title="UAVT(Adres) Kodu"
        content={
          <>
            <InfoIcon fontSize="inherit" color="primary" /> &nbsp; UAVT (Ulusal Adres Veri Tabanı) kodu ülke sınırları
            içindeki tüm konutlara ait 10 haneli özel bir numaradır. 1 Mart 2013'den itibaren zorunlu deprem sigortası
            poliçesi işlemlerinde ve daha birçok işlemde kullanılmaya başlanmıştır.
          </>
        }
      >
        <div>
          <Typography variant="caption">
            <HelpOutlineIcon style={{ fontSize: "inherit" }} />
            &nbsp;UAVT(Adres) kodu nedir ?
          </Typography>{" "}
        </div>
      </KoalayDialog>

      <Grid container>
        <br />
        <Grid item xs={8}>
          <Controller
            control={control}
            defaultValue=""
            name="existAddress.uavt"
            rules={{
              required: true,
              validate: {
                validation: (value) => {
                  const code = localStorage.getItem('uavtCode')
                  return code && code !== 'undefined'
                },
              }
            }}
            render={({ field: { value, onChange } }) => <TextField
              error={errors?.existAddress?.uavt}
              size="small"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              variant="outlined"
              fullWidth
              placeholder="UAVT Kodu"
              value={value}
              onChange={(e) => { setUavtCode(e.target.value); onChange(e.target.value) }}
              onInput={(e) => {
                //TODO: maxlength ayarlamak için workaround. buraya daha temiz bir yöntem bulalım
                if (e.target.value) {
                  const val = parseInt(e.target.value);
                  e.target.value = isNaN(val) ? "" : Math.max(0, val).toString().slice(0, 10);
                }
              }}
            />}
          />

        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            disabled={!uavtCode}
            onClick={() => {
							findAddress();
							triggerGAEvent(gaEvents.PolicyAddressNewUavt)
						}}
            style={{ marginLeft: 5 }}
          >
            <SearchIcon /> Ara
          </Button>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default UAVTFind;
