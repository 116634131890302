import React, { forwardRef, useContext } from "react";
import { Panel } from "../../../../../components/Panel";
import { AppContext } from "../../../../../providers/AppProvider";
import { DaskHomeContext } from "../context/DaskHomeContext";
import { useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { Alert, Skeleton } from "@material-ui/lab";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KoalayButton } from "../../../../../components/KoalayButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useMemo } from "react";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  mainRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  hourglassIcon: {
    position: 'relative',
    width: '20px',
    height: '25px',
    zIndex: '9',
    background: 'rgba(255, 255, 255, 0.4) url(https://static.koalay.com/Images/KoalayApp/dask/hourglass.gif) no-repeat center',
    backgroundSize: '25px',
    marginRight: '10px',
    marginLeft: '5px',
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
  }
}));

const MultipleQuotations = forwardRef(
  ({ completeSection, isActive, quotations }, ref) => {
    const {
      productParameters,
      loading,
      multipleDaskQuotes,
      goPreviousSection,
    } = useContext(DaskHomeContext);
    const classes = useStyles();
    const { isMobile } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const goNextStep = async (quote) => {
      completeSection(quote);
    };

    useEffect(() => {
      const errorNotNull = (quote) => quote.error !== null;
      if (multipleDaskQuotes.length !== 0 && multipleDaskQuotes.every(errorNotNull)) {
        enqueueSnackbar('Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.', { variant: 'error' });
      }
    }, [enqueueSnackbar, multipleDaskQuotes]);
    return useMemo(() => (
      <div
        style={{
          opacity: !isActive ? "0.5" : "unset",
          pointerEvents: !isActive ? "none" : "unset",
        }}
      >
        <Panel
          loading={loading}
          ref={ref}
          title={"Dask Poliçe Teklifleri"}
          
        >
          {multipleDaskQuotes.length !== 0 ? <Alert icon={<InfoIcon fontSize="inherit" />} severity="info">
            <Typography variant="caption">
              Dask(Zorunlu Deprem Sigortası) için ödenmesi gereken prim tutarları aşağıdaki gibidir. Dask poliçe teminatı, "Zorunlu Deprem Sigortası Tarife ve Talimat Birliği" ile belirlendiğinden sigorta şirketi bazlı değişiklik göstermez. 
            </Typography>
          </Alert> : <div className={classes.row}>
                <div className={classes.hourglassIcon} style={{bottom: !isMobile && '2px'}}/>
                <Typography variant="h6" style={{fontSize: '1rem'}}>
                  Teklifleriniz yükleniyor...
                </Typography>
            </div>}
        </Panel>
        {multipleDaskQuotes.length !== 0
          ? multipleDaskQuotes
              .sort((a, b) => {
                if (a.grossPremium !== 0 && b.grossPremium === 0) return -1;
                if (a.grossPremium === 0 && b.grossPremium !== 0) return 1;
                return 0;
              })
              .map((quote) => (
                <Panel
                  loading={loading}
                  className={classes.mainRow}
                  style={{
                    height: isMobile ? "158px" : "109px",
                    display: "flex",
                    padding: "24px",
                  }}
                  icon={<div className={classes.hourglassIcon}></div>}
                >
                  {quote.error === null ? (
                    <Grid
                      container
                      spacing={2}
                      justify="flex-start"
                      alignItems="center"
                      style={{ textAlign: "center", opacity: quote?.error !== null ? "0.5" : "unset", }}
                    >
                        <Grid item sm={4} xs={12} style={{ order: isMobile && -1 }}>
                          <img
                            alt="dask-premium-logo"
                            style={{
                              float: isMobile && "left",
                              marginLeft: isMobile && "12px",
                              width: isMobile ? '25%' : 100,
                              display: "inline",
                            }}
                            src={`https://static.koalay.com/images/koalayapp/dask/insurerlogos/${
                              quote?.insurerElwinName !== null
                                ? quote?.insurerElwinName
                                : quote?.insurerCommercialName
                            }.jpg`}
                          />
                        </Grid>
                        <Grid item sm={4} xs={6}>
                          <Typography
                            variant="h5"
                            style={{
                              fontWeight: 700,
                              fontSize: isMobile ? "28px" : "1.8rem",
                            }}
                            color="primary"
                          >{quote?.grossPremium} TL</Typography>
                        </Grid>
                        <Grid item sm={4} xs={6}>
                          {quote.error === null ? (
                            <KoalayButton
                              style={{ width: !isMobile ? "168px" : "108px" }}
                              variant="contained"
                              color="primary"
                              onClick={() => goNextStep(quote)}
                            >
                              Satın Al
                            </KoalayButton>
                          ) : (
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "#373B3F",
                              }}
                            >
                              Sigorta şirketi teklif vermedi
                            </p>
                          )}
                        </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      justify="flex-start"
                      alignItems="center"
                      style={{ textAlign: "center", opacity: quote?.error !== null ? "0.5" : "unset", }}
                    >
                        <Grid item sm={4} xs={6} style={{ order: isMobile && -1 }}>
                        <img
                          alt="dask-premium-logo"
                          style={{
                            float: isMobile && "left",
                            marginLeft: isMobile && "12px",
                            width: isMobile ? '80%' : 100,
                            display: "inline",
                          }}
                          src={`https://static.koalay.com/images/koalayapp/dask/insurerlogos/${
                            quote?.insurerCommercialName !== null
                              ? quote?.insurerCommercialName
                              : quote?.insurerElwinName
                          }.jpg`}
                        />
                      </Grid>
                      {!isMobile && <Grid item sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: 700,
                            fontSize: isMobile ? "28px" : "1.8rem",
                          }}
                          color="primary"
                        >-</Typography>
                      </Grid>}
                      <Grid item sm={4} xs={6}>
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#373B3F",
                            }}
                          >
                            Sigorta şirketi teklif vermedi
                          </p>
                      </Grid>
                    </Grid>
                  )}
                </Panel>
              ))
          :   /* Skeleton - showing until offers load */
              Array(4).fill(null).map((x, i) => (
                <Panel
                  key={i}
                  className={classes.mainRow}
                  style={{
                    height: isMobile ? "158px" : "109px",
                    display: "flex",
                    padding: '0 24px'
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    justify="flex-start"
                    alignItems="center"
                    style={{ textAlign: "center" }}
                  >
                    <Grid item style={{order: isMobile && -1, height: isMobile ? '53px' : '70px', paddingTop: '0', paddingBottom: '0'}} sm={4} xs={12} >
                      <Skeleton style={{height: '100%', width: isMobile && '100px'}} variant="rectangular" />
                    </Grid>
                    <Grid item style={{height: '45px'}} sm={4} xs={6}>
                      <Skeleton style={{height: '100%'}} variant="rectangular"/>
                    </Grid>
                    <Grid item style={{height: '45px'}} sm={4} xs={6}>
                      <Skeleton style={{height: '100%'}} variant="rectangular" />
                    </Grid>
                    </Grid>
                </Panel>
              ))
          }
        <Button
          startIcon={<KeyboardArrowUpIcon />}
          onClick={() => goPreviousSection()}
          style={{ paddingTop: "5px", paddingBottom: "15px" }}
        >
          Önceki Adıma Geri Dön
        </Button>
      </div>
    ), [classes.mainRow, classes.hourglassIcon, classes.row, goNextStep, goPreviousSection, isActive, isMobile, loading, multipleDaskQuotes, ref]);
  }
);

export default MultipleQuotations;
