import axios from 'axios';
import queryString from 'query-string';

const API = process.env.REACT_APP_API;

const consumer = queryString
  .stringify(queryString.parse(window.location.search))
  .replaceAll('&', ';')
  .replaceAll('=', ':');

const axiosDotNetInstance = axios.create({
  baseURL: API + '/api/',
  headers: {
    'Content-Type': 'application/json',
    RequestVerificationToken: localStorage.getItem('aft'),
    'Access-Control-Allow-Origin': '*',
  }
});

// Add a request interceptor
axiosDotNetInstance.interceptors.request.use(
  function (config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        RequestVerificationToken: localStorage.getItem('aft')
      }
    };
  },
  function (error) {}
);

// Add a response interceptor
axiosDotNetInstance.interceptors.response.use(
  function (response) {
    if (response.data.Result.code && response.data.Result.code !== '0') {
      return Promise.reject({
        message: response.data.Result.message,
        code: response.data.Result.code
      });
    }
    return response;
  },
  function (error) {
    if(error.response.status==302){
        document.location.href = error.response.data.redirectUrl;
    }else if(error.response.status==403){
      document.location.pathname = "/Error/Unauthorized";
    }
    //  setLoading(false);
    return Promise.reject(error);
  }
);

export default axiosDotNetInstance;
