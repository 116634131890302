// @ts-nocheck
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Box, TextField } from "@material-ui/core";
import React, { useContext, useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useFormContext } from "react-hook-form";
import { PolicyType, UnhandledErrorText } from "../../../constants";
import { DaskHomeContext } from "../context/DaskHomeContext";
import PolicyTypeRadioGroup from "../components/PolicyTypeRadioGroup";
import { Panel } from "../../../../../components/Panel";
import { KoalayDialog } from "../../../../../components/KoalayDialog";
import GreenCheckbox from "../../../../../components/GreenCheckbox";
import { AppContext } from "../../../../../providers/AppProvider";
import NumberFormat from "../../../../../components/NumberFormat/NumberFormat";
import { KoalayButton } from "../../../../../components/KoalayButton/KoalayButton";
import { useSnackbar } from "notistack";
import UserAgreement from "./../../../../../components/UserAgreement/index";
import BrokerAgreement from "../../../../../components/BrokerAgreement";
import KvkkInformation from "../../../../../components/KvkkInformation";
import { getFormFirstError } from "./../../../../../helpers/getFormFirstError";
import { postCustomerInfo } from "../../../../../apiServices/sideProductService";
import { isEmpty } from "lodash";
import delayHelper from "./../../../../../helpers/delayHelper";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format, isValid, compareAsc, add } from "date-fns";

/**
 * Dask-Personal Info Section
 */
const PersonalInfoEntrySection = ({ completeSection, isActive }) => {
  /* -------------------------------------------------------------------------- */
  /*                                   HOOKS                                    */
  /* -------------------------------------------------------------------------- */

  const captchaRef = useRef();
  const captchaId = localStorage.getItem("captchaId");
  const { loading, setLoading, isForcePrepare } = useContext(DaskHomeContext);
  const { funnelData, isMobile, setIsRenewal } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBirthDateDisabled, setDisableBirthDate] = useState(true);
  const { isReferenced } = funnelData.consumer;

  const { formState, control, setValue, getValues, trigger, watch } = useFormContext();

  const { errors } = formState;
  const captchaEnabled = false;
  const policyType = watch("policyType");

  /**
   * adjusts the text field's width according to the mobile information
   */
  const textFieldWidth = isMobile ? "100%" : "80%";

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (funnelData.consumer?.referenceParams?.identityNo.length > 10) {
      setDisableBirthDate(false);
    } else {
      setDisableBirthDate(true);
    }
  }, [funnelData]);
  const goNextStep = async () => {
    //TODO şu error handling kısmını ayır
    trigger();
    //TODO sleepten kurtul
    await delayHelper(100);

    const data = getValues("customer");

    if (!isEmpty(errors?.customer)) {
      showError(errors?.customer);
      return;
    } else if (!isEmpty(errors?.captchaResponse)) {
      showError(errors?.captchaResponse);
      return;
    } else if (!isEmpty(errors?.isAgreementAccepted)) {
      showError(errors?.isAgreementAccepted);
      return;
    } else if (policyType === PolicyType.RENEWAL && !isEmpty(errors?.oldPolicyNo)) {
      showError(errors?.oldPolicyNo);
      return;
    }
    const captchaResponse = getValues("captchaResponse");
    const oldPolicyNo = getValues("oldPolicyNo");
    let birthDate;

    birthDate = isValid(data.birthDate) && format(data.birthDate, "yyyy-MM-dd");

    const { phoneNumber, identityNo } = data;


		const currentURL = new URL(window.location.href);
		const currentParams = new URLSearchParams(currentURL.search);
		const campSrcValue = currentParams.get('CampSrc');

    const custModel = {
      sbn: funnelData?.consumer?.partnerSbn,
      extRefId: funnelData?.consumer?.dealerCode,
      gCLID: funnelData?.consumer?.gclid,
      channelId: 1,
      functionalBranchId: 2,
      campSrc: funnelData?.campsrc || campSrcValue,
      phoneNumber,
      birthDate: birthDate === false ? null : birthDate,
      captchaResponse,
      acceptTermsAndConditions: true,
      userGuid: null,
      gclientId: "",
    };

    //! TODO-> NumberMask componenti init set olunca formda değerini sıfırlatıyor.
    //! Referans akışta phoneNumber sıfırlandığı için böyle bir work-around oluşturuldu.
    //! Buradan kurtul !!!
    if (isReferenced) {
      custModel.phoneNumber = funnelData?.consumer?.referenceParams?.phone;
    }

    //apicall
    try {
      setLoading(true);
      const response = await postCustomerInfo(identityNo, oldPolicyNo, custModel);

      const { customer, address, building } = response.data;

      setValue("existAddress", address || {});
      setValue("customer", customer);

      if (building) {
        setValue("defaultBuilding", {
          ...building,
          constructionType: building.constructionType + "",
          usageType: building.usageType + "",
          constructionYear: building.constructionYear + "",
          floorArea: building.floorArea + "",
          buildingStory: building.buildingStory + "",
        });
      }

      setValue("captchaResponse", undefined);
      if (captchaRef && captchaRef.current) {
        captchaRef.current.reset();
      }

      completeSection();
    } catch (error) {
      if (oldPolicyNo && error?.code === "100") {
        enqueueSnackbar(
          "Aradığınız kriterlere uygun poliçe bulunamadı! 'Yeni Poliçe' adımından devam ederek birkaç basit adımda yeni poliçenizi kolaylıkla oluşturabilirsiniz.",
          { variant: "error" }
        );
      } else if (error?.code === "114") {
        enqueueSnackbar("Güvenlik kodunu hatalı girdiniz. Lütfen tekrar deneyiniz.", { variant: "error" });
      } else if (error?.code === "113") {
        enqueueSnackbar("Çok fazla sayıda deneme yaptınız. Lütfen daha sonra tekrar deneyin.", { variant: "error" });
      } else if (error?.code === "100") {
        enqueueSnackbar("Kişi bulunamadı!", { variant: "error" });
      } else if (error?.code === "99") {
        enqueueSnackbar("Girdiğiniz bilgiler hatalıdır, lütfen kontrol ediniz.", { variant: "error" });
      } else {
        enqueueSnackbar(UnhandledErrorText, { variant: "error" });
      }

      setValue("captchaResponse", undefined);

      if (captchaRef && captchaRef.current) {
        captchaRef.current.reset();
      }
    } finally {
      setLoading(false);
    }
  };
  const showError = (errors) => {
    enqueueSnackbar(getFormFirstError(errors), { variant: "error" });
  };

  const handlePolicyTypeChange = (policyType) => {
    setValue("oldPolicyNo", undefined);
    setValue("policyType", policyType);
    setIsRenewal(policyType === 1 ? false : true);
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  const GetRenewalPolicyInputs = () => {
    return (
      <>
        <Controller
          name="oldPolicyNo"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Lütfen poliçe numaranızı girin.",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors.oldPolicyNo}
              label="DASK poliçe numaranız"
              disabled={!isActive || isForcePrepare}
              style={{
                width: textFieldWidth,
              }}
              size="small"
              helperText={
                <>
                  <KoalayDialog
                    title="Poliçe numaramı bilmiyorum"
                    content={
                      <>
                        <img
                          src="https://www.dask.gov.tr/img/anasayfa/aloDask125.png"
                          style={{ float: "left", width: "100px" }}
                          alt="alo dask 125"
                        />
                        Haftaiçi ve cumartesi günleri (resmi tatiller dahil) 09:00 - 19:00 saatleri arasında,
                        telefonunuzdan 125'i tuşlayarak ALO DASK desteği ile poliçe numaranızı öğrenebilirsiniz.
                      </>
                    }
                  >
                    <span>
                      <HelpOutlineIcon fontSize="inherit" /> Poliçe numaramı bilmiyorum
                    </span>
                  </KoalayDialog>
                </>
              }
              variant="outlined"
            />
          )}
        />
        <br />
        <br />
      </>
    );
  };

  function getPolicyTypeSelect() {
    if (!isMobile) {
      return (
        <div>
          <PolicyTypeRadioGroup disabled={!isActive} value={policyType} onChange={handlePolicyTypeChange} />
          <br />
          <br />
        </div>
      );
    } else if (isMobile && !policyType) {
      return (
        <Panel>
          <h3>DASK (Zorunlu Deprem Sigortası)</h3>
          <b>Merhaba,</b>
          <p>Koalay ile Dask poliçesi satın almak çok kolay !</p>
          <p>Şimdi lütfen yapmak istediğiniz işlemi seçiniz.</p>
          <br />
          <KoalayButton size="large" onClick={() => setValue("policyType", PolicyType.NEW)}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div> YENİ POLİÇE</div>
              <div style={{ fontSize: 12, fontWeight: 400 }}>Adresinize ilk kez DASK poliçesi yaptırıyorsanız</div>
            </div>
          </KoalayButton>
          <br />
          <br />
          <KoalayButton size="large" onClick={() => setValue("policyType", PolicyType.RENEWAL)}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div> YENİLEME POLİÇE</div>
              <div style={{ fontSize: 12, fontWeight: 400 }}>Adresinize önceden DASK poliçesi yaptırdıysanız</div>
            </div>
          </KoalayButton>
        </Panel>
      );
    } else {
      return <React.Fragment />;
    }
  }
  return (
    <div>
      {!isForcePrepare && getPolicyTypeSelect()}

      {(policyType || !isMobile) && (
        <Panel title="Kimlik Bilgileri" icon={faUser} loading={loading}>
          {/* {!isSelfService && (
            <>
              <Alert severity="info">
                <Typography variant="caption">
                  Bilgilerin her adımda poliçe sahibi olacak kişiye sorarak
                  doldurulması gerekmektedir. Poliçe sahibi olacak kişiden,
                  poliçe tanzimi gerçekleştirilmeden önce tüm bilgilerin
                  doğruluğunun teyidinin alınması gerekmektedir. İl ve/veya İlçe
                  bilgisinin yanlış olması durumunda poliçede adres değişikliği
                  yapılamaz ve iptali gerçekleştirilemez. DASK poliçesi zorunlu
                  bir poliçedir, sadece poliçenin mükerrer olması durumunda
                  iptali gerçekleştirilebilir. Mükerrerlik dışında hiç bir
                  nedenle DASK poliçesi iptal edilemez, para iadesi yapılamaz.
                  Konutun satışı yapılmış ve mevcutta poliçesi var ise, konutun
                  yeni sahibi, zeyil yaptırarak poliçeyi devam ettirebilir.
                </Typography>
              </Alert>
              <br />
            </>
          )} */}

          <Controller
            name="customer.identityNo"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Lütfen T.C kimlik veya Vergi numarınızı giriniz.",
              },
              minLength: {
                value: 10,
                message: "Lütfen T.C kimlik veya Vergi numarınızı kontrol ediniz.",
              },
              validate: {
                validation: (value) => {
                  const tcknRegex = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
                  const vergiNoRegex = /^[0-9]{10}$/;
                  if (value.match(tcknRegex) || value.match(vergiNoRegex)) {
                    return true;
                  }
                  return "Lütfen T.C kimlik veya Vergi numarınızı kontrol ediniz.";
                },
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors?.customer?.identityNo ? true : false}
                label="TC Kimlik veya Vergi Numaranız"
                helperText={!isMobile && "*TC Kimlik numaranız, adres bilgilerinizi sorgulayabilmemiz için gereklidir."}
                variant="outlined"
                disabled={!isActive || isForcePrepare || isReferenced}
                style={{
                  width: textFieldWidth,
                }}
                size="small"
                onInput={(e) => {
                  // TODO: maxlength ayarlamak için workaround. buraya daha temiz bir yöntem bulalım
                  if (e.target.value) {
                    const val = e.target.value.replace(/\D/g, "");
                    const intVal = parseInt(e.target.value);
                    e.target.value = isNaN(intVal) ? "" : val.slice(0, 11);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.target.value.length > 10) {
                    setDisableBirthDate(false);
                  } else {
                    setDisableBirthDate(true);
                  }
                  const val = e.target.value.replace(/\D/g, "");
                  const intVal = parseInt(e.target.value);
                  field.value = isNaN(intVal) ? "" : val.slice(0, 11);
                  e.target.value = isNaN(intVal) ? "" : val.slice(0, 11);
                }}
              />
            )}
          />
          {!isBirthDateDisabled && (
            <>
              <br />
              <br />
              <Controller
                name="customer.birthDate"
                control={control}
                error={errors?.customer?.birthDate ? true : false}
                rules={{
                  validate: (date) => {
                    if (!date && getValues("customer").identityNo.length > 10) {
                      return "Lütfen doğum tarihinizi giriniz";
                    }
                    if (getValues("customer").identityNo.length > 10 && (!isValid(date) || date.getFullYear() < 1900)) {
                      return "Lütfen geçerli bir doğum tarihi giriniz";
                    }
                    return compareAsc(new Date(), add(date, { years: 18 })) === -1
                      ? " Devam edebilmek için 18 yaşından büyük olmalısınız"
                      : true;
                  },
                }}
                render={({ field }) => (
                  <KeyboardDatePicker
                    className="birth-date"
                    error={errors?.customer?.birthDate ? true : false}
                    disableToolbar
                    disabled={!isActive}
                    defaultValue={undefined}
                    size="small"
                    helperText={
                      !isMobile &&
                      "*Doğum Tarihi bilgisi sigorta şirketlerinin teklif verebilmesi için zorunlu hale getirilmiştir"
                    }
                    style={{
                      width: textFieldWidth,
                    }}
                    {...field}
                    placeholder="GG/AA/YYYY"
                    variant="inline"
                    inputVariant="outlined"
                    keyboardIcon=""
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Doğum Tarihiniz"
                    KeyboardButtonProps={{
                      display: "none",
                    }}
                  />
                )}
              />
            </>
          )}

          <br />
          <br />
          {policyType === PolicyType.RENEWAL && GetRenewalPolicyInputs()}
          <Controller
            name="customer.phoneNumber"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Lütfen telefon numarınızı giriniz.",
              },
              pattern: {
                value: /^(5)([0-9]{9})$/,
                message: "Lütfen telefon numaranızı kontrol ediniz.",
              },
            }}
            render={({ field }) => (
              <NumberFormat
                {...field}
                error={errors?.customer?.phoneNumber ? true : false}
                label="Telefon Numaranız"
                helperText={
                  !isMobile &&
                  "*Telefon numaranız, poliçelendirme sonrası sizinle iletişim kurabilmemiz için gereklidir."
                }
                variant="outlined"
                disabled={!isActive || isReferenced}
                format="(###) ### ####"
                style={{
                  width: textFieldWidth,
                }}
                size="small"
              />
            )}
          />
          <br />
          <br />

          {isActive && (
            <>
              <Controller
                name="isAgreementAccepted"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Devam etmek için kullanıcı sözleşmesini okuyup kabul etmeniz gerekiyor",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Box display="flex">
                    <GreenCheckbox checked={value || false} onChange={(e) => onChange(e.target.checked)} />
                    <div
                      style={{
                        fontSize: "13px",
                        cursor: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <KoalayDialog title="Kullanıcı Sözleşmesi" content={<UserAgreement />}>
                          {`${"  Kullanıcı Sözleşmesini "}`}
                        </KoalayDialog>
                        okudum ve Koalay.com’u
                        <KoalayDialog
                          title="Brokerlik Yetkilendirmesi ve Brokerlik Sözleşmesi"
                          content={<BrokerAgreement />}
                        >
                          {`${" sigorta brokerim olarak yetkilendiriyorum. "}`}
                        </KoalayDialog>
                      </span>
                    </div>
                  </Box>
                )}
              />
              {isMobile && <br />}
              <div>
                Kişisel Verilerin Korunması Hakkında Bilgilendirme Metni'ne
                <KoalayDialog
                  title="6698 sayılı Kişisel Verilerin Korunması Kanunu Kapsamında Bilgilendirme"
                  content={<KvkkInformation />}
                >
                  {`${" buradan "}`}
                </KoalayDialog>
                ulaşabilirsiniz.
              </div>
              <br />
              {captchaEnabled && (
                <>
                  <Controller
                    name="captchaResponse"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Lütfen google reCaptcha doğrulamasını yapınız.",
                      },
                    }}
                    render={({ field: { onChange } }) => (
                      <ReCAPTCHA
                        ref={captchaRef}
                        size="small"
                        sitekey={captchaId}
                        onChange={(captchaResponse) => onChange(captchaResponse)}
                      />
                    )}
                  />
                  <br />
                </>
              )}

              {!isForcePrepare && (
                <KoalayButton color="primary" endIcon={<ArrowForwardIosIcon />} onClick={goNextStep}>
                  Sorgula
                </KoalayButton>
              )}
            </>
          )}
        </Panel>
      )}
    </div>
  );
};

export default React.memo(PersonalInfoEntrySection);
