import React, { useContext } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { Box, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import PaymentContext from '../context/PaymentContext';
import PhoneNumberInput from '../../../../../components/PhoneNumberInput';
import GreenCheckbox from '../../../../../components/GreenCheckbox';
import Alert from '@material-ui/lab/Alert';
import { Skeleton } from '@material-ui/lab';
import { AppContext } from '../../../../../providers/AppProvider';

const PersonalInfoForm = ({ form }) => {
  const { control, formState } = form;
  const { errors } = formState;
  const { customer, isInit } = useContext(PaymentContext);
  const { funnelData } = useContext(AppContext);
  const isDealer = funnelData?.consumer?.isDealer;

  return (
    <>
      <h1
        style={{
          fontSize: 22,
          fontWeight: 'bold',
          color: '#0055a5',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <PersonIcon /> &nbsp; Kişisel Bilgiler
      </h1>
      <Divider />
      <br />
      {isInit ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
					 		style={{display: customer?.name == null || customer?.lastName == null ? "none": 'block'}}
              disabled={true}
              fullWidth
              size="small"
              variant="outlined"
              value={`${customer?.name} ${customer?.lastName}`}
              label="Adınız ve soyadınız"
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneNumberInput
              disabled={true}
              fullWidth
              size="small"
              variant="outlined"
              value={customer?.mobilePhone}
              label="Cep telefonu numaranız"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="customer.eMail"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^(?=.{6,265}$)(-|_)*[^\W_](?:[+\w.-]*[^\W_])*(-|_|\.)*@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]{2,}\.)+)(?:[a-zA-Z]{2,13}|[0-9]{1,2})\]?$/i,
                  message: 'Lütfen geçerli bir mail adresi giriniz.'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={errors.customer?.eMail}
                  label="E-posta adresiniz"
                />
              )}
            />
          </Grid>
          {!isDealer && (
            <Grid item xs={12}>
              <Controller
                name="customer.EBulletin"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Box display="flex" alignItems="center">
                    <GreenCheckbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                    <div style={{ fontSize: '13px', cursor: 'auto' }}>
                      Ürünler, yenilikler ve kampanyalar hakkında e-bülten almak
                      istiyorum.
                    </div>
                  </Box>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Alert severity="warning">
              <Typography variant="subtitle2">Lütfen dikkat!</Typography>
              <Typography variant="caption">
                İl, İlçe, Bucak-Köy ve Mahalle bilgilerinin poliçe üzerinde
                hatalı belirtilmesi durumunda, mevzuat gereği poliçe iptal
                edilememekte ve poliçe üzerinde değişiklik yapılamamaktadır.
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      ) : (
        Array(10)
          .fill(null)
          .map((x, i) => <Skeleton key={i} />)
      )}
    </>
  );
};

export default PersonalInfoForm;
