export const FTRBZDLK = {
  header: {
    left: {
      authorizedAgencyLogo: false,
    },
    right: {
      phoneNumber: "0216 570 08 16",
      partnerLogo: true,
    },
  },
};