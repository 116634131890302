/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { isObject } from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AppContext } from '../../providers/AppProvider';

const Autocomplete = ({
  onChange,
  value,
  label,
  variant,
  options = [],
  error,
  displayField = 'Text',
  valueField = 'Value',
  searchable = true,
  loading,
  disabled,
  ...rest
}) => {
  const { isMobile } = useContext(AppContext);

  const getOptionLabel = (option) => {
    if (option) {
      if (option[displayField]) {
        return option[displayField];
      } else {
        return options.find((x) => x.Value === option)?.Text;
      }
    }
    return undefined;
  };
  return (
    <MuiAutocomplete
      {...rest}
      disabled={disabled || loading}
      popupIcon={
        <ArrowDropDownIcon color={rest.disabled ? 'default' : 'primary'} />
      }
      onChange={(event, newValue) => {
        onChange(newValue ? newValue[valueField] : undefined, newValue);
      }}
      getOptionLabel={getOptionLabel}
      value={value}
      options={options}
      getOptionSelected={(option, value) =>
        isObject(value)
          ? option[valueField] === value[valueField]
          : option[valueField] === value
      }
      clearOnEscape
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={params.disabled || loading}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress color="primary" size={20} />
              </InputAdornment>
            ) : (
              params.InputProps.endAdornment
            )
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            readOnly: searchable && !isMobile ? false : true
          }}
          error={error}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default Autocomplete;
