import React, { useEffect, useState } from "react";
import { getFunnelData } from "../../apiServices/funnelService";
import AppContext from "./AppContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Layout } from "./../../components/Layout";
import { configs } from "../../configs";
import { SnackbarProvider } from "notistack";
import { Backdrop, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    backgroundColor: "transparent",
  },
  overlayIcon: {
    width: "100%",
    height: "100%",
    background:
      " rgba(255, 255, 255, 0.4) url(https://static.koalay.com/Images/KoalayApp/dask/hourglass.gif) no-repeat   center",
    backgroundSize: "60px",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 9,
  },
  snack: {
    maxWidth: 400,
    fontWeight: 700,
  },
}));

const AppProvider = ({ children }) => {
  /* -------------------------------------------------------------------------- */
  /*                                   STATES                                   */
  /* -------------------------------------------------------------------------- */

  const [funnelData, setFunnelData] = useState(undefined);
  const [funnel, setFunnel] = useState(2);
  const [mainLogoHref, setMainLogoHref] = useState("/");
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 600);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [theme, setTheme] = useState(null);
  const [isRenewal, setIsRenewal] = useState(false);
  const [SSWLConfig, setSSWLConfig] = useState({});
  const mobileBreakPoint = useMediaQuery("(max-width:600px)");
  const classes = useStyles();

  /* -------------------------------------------------------------------------- */
  /*                               CONTEXT VALUES                               */
  /* -------------------------------------------------------------------------- */
  const contextValues = {
    funnelType: funnel,
    setFunnelType: setFunnel,
    funnelData,
    mainLogoHref,
    setMainLogoHref,    
    isMobile,
    globalLoading,
    setGlobalLoading,
    SSWLConfig,
		isRenewal,
		setIsRenewal,
  };

  /* -------------------------------------------------------------------------- */
  /*                                   EFFECTS                                  */
  /* -------------------------------------------------------------------------- */
  //TODO BURAYA initiliaze bir useEffect koyup isMobile düzenlemek lazım. dire
  useEffect(() => {
    setIsMobile(mobileBreakPoint);
  }, [mobileBreakPoint]);

  useEffect(() => {
    async function _getFunnelData() {
      const res = (await getFunnelData(funnel)).data;
      localStorage.setItem("aft", res.aft);
      localStorage.setItem("daskmultiplequotationenabled", res.daskmultiplequotationenabled);
    	localStorage.setItem("consumer", JSON.stringify(res.consumer));
			localStorage.setItem('captchaId', res.recaptchaclientsecret)
      const partnerSbn = res.consumer?.partnerSbn;
      if (partnerSbn && configs.SSWL[partnerSbn]) {
        setSSWLConfig({ partnerSbn, ...configs.SSWL[partnerSbn] });
      }
      let appTheme = await import("../../helpers/theme");
      setTheme(appTheme.theme);
      setFunnelData(res);
    }
    _getFunnelData();
  }, [funnel]);

  useEffect(() => {
    funnelData?.consumer?.isReferenced
      ? setMainLogoHref("#")
      : setMainLogoHref("/");
  }, [funnelData]);

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  if (theme !== null) {
    return (
      <AppContext.Provider value={contextValues}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              ref={notistackRef}
              dense={isMobile ? true : false}
              hideIconVariant={true}
              anchorOrigin={
                isMobile
                  ? {
                      vertical: "top",
                      horizontal: "center",
                    }
                  : {
                      vertical: "bottom",
                      horizontal: "left",
                    }
              }
              classes={{ containerRoot: classes.snack }}
              action={(key) => (
                <Button onClick={onClickDismiss(key)} style={{ color: "#fff" }}>
                  Kapat
                </Button>
              )}
            >
              <Layout>{children}</Layout>
              <Backdrop className={classes.backdrop} open={!funnelData}>
                <div className={classes.overlayIcon} />
              </Backdrop>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </AppContext.Provider>
    );
  } else {
    return "";
  }
};

export default AppProvider;
