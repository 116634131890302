// @ts-nocheck
import React, { forwardRef, useContext, useEffect } from 'react';
import { DaskHomeContext } from '../../context/DaskHomeContext';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@material-ui/core';
import { Panel } from '../../../../../../components/Panel';
import { AddressType } from '../../../../constants';
import NewAdress from './NewAddress';
import { ExistAddress } from './ExistAddress';
import { PolicyType } from './../../../../constants';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AppContext } from '../../../../../../providers/AppProvider';
import { useFormContext } from 'react-hook-form';
import { triggerGAEvent } from '../../../../../../helpers/gaEvent';
import { gaEvents } from '../../../../../../data/gaEvents';

const AddressEntrySection = forwardRef(({ completeSection, isActive }, ref) => {
  const { loading, goPreviousSection } = useContext(DaskHomeContext);
  const { isMobile,isRenewal } = useContext(AppContext);
  const { getValues, watch, setValue } = useFormContext();
  const existAddress = getValues('existAddress');
  const customer = getValues('customer');
  const addressType = watch('addressType');
  const policyType = watch('policyType');

	useEffect(() => {
		if(isActive){
			triggerGAEvent(isRenewal ? gaEvents.RenewalAddressSeen : gaEvents.PolicyAddressSeen)
		}
	}, [])
	
  useEffect(() => {
    if (!addressType && isActive) {
      setValue(
        'addressType',
        existAddress && Object.keys(existAddress).length > 1 ? AddressType.EXIST : AddressType.NEW
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div>
      <Panel ref={ref} loading={loading}>
        <h1 style={{ fontSize: 20,display: customer?.name == null || customer?.lastName == null ? "none": 'block'}} >
          Sayın{' '}
          <b>{`${
            (customer?.name || '') + ' ' + (customer?.lastName || '')
          },`}</b>
        </h1>
        <p>
          DASK poliçenizin primini hesaplayabilmemiz için adres bilgilerinizi
          onaylayın ve aşağıdaki alanları eksiksiz olarak doldurun.
        </p>
      </Panel>
      <Panel
        icon={faMapMarkedAlt}
        title="Poliçe Yapılacak Adres"
        goBack={goPreviousSection}
      >
        <Box>
          {policyType === PolicyType.RENEWAL ||
          addressType === AddressType.EXIST ? (
            <ExistAddress
              goNextStep={completeSection}
              isActive={isActive}
              changeAddressType={() => setValue('addressType', AddressType.NEW)}
            />
          ) : (
            <NewAdress
              goNextStep={completeSection}
              isActive={isActive}
              //! cannot change addressType if there isn't existAddress
              changeAddressType={
                existAddress
                  ? () => setValue('addressType', AddressType.EXIST)
                  : undefined
              }
            />
          )}
        </Box>
        {isActive && !isMobile && (
          <>
            <br />
            <Button
              startIcon={<KeyboardArrowUpIcon />}
              onClick={goPreviousSection}
            >
              Önceki Adıma Geri Dön
            </Button>
          </>
        )}
      </Panel>
    </div>
  );
});

export default React.memo(AddressEntrySection);
