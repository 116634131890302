// @ts-nocheck
import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { Panel } from '../../../../../components/Panel';
import { AppContext } from '../../../../../providers/AppProvider';

const PaymentHeader = ({ logoSrc, cost }) => {
  const { isMobile } = useContext(AppContext);
  return (
    <Panel>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <img width="100" src={`https://static.koalay.com/images/koalayapp/dask/insurerlogos/${logoSrc}.jpg`} alt="" />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            textAlign: 'center',
            fontSize: 24,
            color: '#737373',
            fontWeight: 700
          }}
        >
          DASK
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            fontSize: isMobile ? 24 : 28,
            color: '#0055a5',
            fontWeight: 700,
            textAlign: 'right'
          }}
        >
          {cost} ₺
        </Grid>
      </Grid>
    </Panel>
  );
};

export default PaymentHeader;
